import React from 'react';
import UIComponent from "../../hoc/ui-component";
import LayoutBuilder from "../../layout-builder/layout-builder";

class PureAFButton extends React.PureComponent {

    getAlign() {
        if (!this.props.data.align) { return 'flex-start'; }
        switch(this.props.data.align.align) {
            case 'left': return 'flex-start';
            case 'right': return 'flex-end';
            case 'center': return 'center';
            default: return 'flex-start';
        }
    }

    render() {
        if (!this.props.data) { return null; }
        const { buttons } = this.props.data.buttons;
        const align = this.getAlign();
        return (
            <div style={{ justifyContent: align }} className='af-buttons-wrapper'>
                {LayoutBuilder.buildButtons(buttons)}
            </div>
        );
    }
}

export const AFButton = UIComponent(PureAFButton, 'af-button', []);
