import React, { PureComponent } from 'react';
import UIComponent from '../hoc/ui-component';
import EventBus from '../event-bus/event-bus';
import { configuration } from '../configuration/configuration';

class PureKMKButtonsTile extends PureComponent {

    renderButton(button) {
        const { color, textColor, style, icon, label, eventName, eventData } = button;
        if (!label) return null;

        const buttonStyle = {
            backgroundColor: color,
            color: textColor,
        };

        if (style) {
            try {
                const overridenStyle = JSON.parse(style);
                Object.assign(buttonStyle, overridenStyle);
            } catch(e) {}
        }

        return (
            <button 
                key={Math.random()}
                className={`basic-button ${icon ? 'with-icon': ''}`}
                style={buttonStyle}
                onClick={() => EventBus.dispatchEvent(eventName, eventData)}
            >
                {icon && <img src={`${configuration.STATICS_URL}${icon}`} alt="Icon"/>}
                <span>{label}</span>
            </button>
        );
    }
    
    render() {
        if (!this.props.data.buttonsContainer) return null;
        const { buttons, align } = this.props.data.buttonsContainer;
        const containerStyle = {
            textAlign: align,
        };
        if (!buttons) return null;

        return (
            <div style={containerStyle}>{buttons.map(button => this.renderButton(button))}</div>
        )
    }
}

export const KMKButtonsTile = UIComponent(PureKMKButtonsTile, 'kmk-buttons-tile', []);
