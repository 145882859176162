import { animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import { configuration } from '../configuration/configuration';

export const APPLICATION_EVENTS = {
    NAVIGATE: 'navigate',
    FORM_SUBMITED: 'form-submit',
    SCROLL: 'scroll-to',
    CONFIRM_NEWSLETTER: 'confirm-newsletter',
    PAGE_POPUP: 'page-popup',
    SET_COOKIE: 'set-cookie'
}

class EventBus {
    static initialize() {
        setDefinedEventListeners();
    }
    static dispatchEvent(name, data) {
        try {
            const eventData = (data && typeof data === 'string') ? JSON.parse(data) : data;
            const event = new CustomEvent(name, { detail: eventData });
            setTimeout(() => document.dispatchEvent(event), 200);
        } catch(e) {
            return;
        }
    }
}

function setDefinedEventListeners() {
    Object.keys(APPLICATION_EVENTS).forEach(eventKey => {
        const event = APPLICATION_EVENTS[eventKey];
        document.addEventListener(event, (data) => {
            EventHandler(event)(data);
        }); 
    })
}

function EventHandler(event) {
    const HANDLER_MAP = {
        [APPLICATION_EVENTS.NAVIGATE]: navigateTo,
        [APPLICATION_EVENTS.FORM_SUBMITED]: formSubmited,
        [APPLICATION_EVENTS.SCROLL]: scrollTo,
        [APPLICATION_EVENTS.CONFIRM_NEWSLETTER]: confirmNewsletter,
        [APPLICATION_EVENTS.PAGE_POPUP]: showPagePopup,
        [APPLICATION_EVENTS.SET_COOKIE]: setCookie
    };

    return HANDLER_MAP[event];
}

const navigateTo = (data) => {
    if (!data || !data.detail || !data.detail.url) return;
    window.location.href = data.detail.url;
}

const scrollTo = (data) => {
    if (!data || !data.detail || !data.detail.anchor) return;
    const domElement = document.getElementById(data.detail.anchor);
    if (!domElement) { return; }
    const bodyRect = document.body.getBoundingClientRect();
    const topOffset = domElement.getBoundingClientRect().top - bodyRect.top;
    scroll.scrollTo(topOffset);
}

const formSubmited = (data) => {}

const showPagePopup = (data) => {
    Reflect.defineProperty(window, 'page_init_popup', { value: data.detail.data, enumerable: true, writable: true, configurable: true });
}

const setCookie = (data) => {
    for (let i = 0; i < data.detail.cookies.length; i++) {
        var d = new Date();
        d.setTime(d.getTime() + (180*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = data.detail.cookies[i].name + "=" + data.detail.cookies[i].value + ";" + expires + ";path=/";
    }
}

const confirmNewsletter = (data) => {
    console.log('Data: ', data);
    const { schema, duplication, anchor } = data.detail.data;
    const emailReg = /\?email=[a-zA-Z0-9@.+,\-|<>]+/gi;
    const masonry = document.querySelector('.masonry-layout');
    masonry.style.opacity = 0;
    try {
        const email = window.location.search.match(emailReg)[0].split('=')[1];
        if (!data || !schema || !email) { window.location.href = "/"; }

        axios.post(`${configuration.API_URL}/public/form`, { _id: schema, fields: [{ name: 'email', value: email }] })
            .then(() => masonry.style.opacity = 1)
            .catch(err => {
                const isDuplicated = (err.response.status===400 && err.response.data.errorMessage.email);                
                if (isDuplicated) {
                    const container = document.getElementById(anchor);
                    try {
                        container.getElementsByTagName('p')[0].innerHTML = duplication;
                        masonry.style.opacity = 1;
                    } catch (e) {}
                }
            })
    } catch(e) {
        window.location.href = "/";
    }
}

export default EventBus;
