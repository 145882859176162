import React, { Component } from 'react';
import axios from 'axios';
import UIComponent from '../hoc/ui-component';
import { configuration } from '../configuration/configuration';
import omit from 'lodash-es/omit';
import './ui-components.css';
import EventBus, { APPLICATION_EVENTS } from '../event-bus/event-bus';
import Check from '../assets/icons/checkbox_white.svg';
import TextArea from './form-fields/text-area';
import RadioButton from './form-fields/radiobutton';
import Conditions from './form-fields/conditions';
import InputField from './form-fields/input';
import Ripples from 'react-ripples'
import Checkbox from "./form-fields/checkbox";

const FIELDS_MAP =  {
    input: InputField,
    textarea: TextArea,
    radiobutton: RadioButton,
    conditions: Conditions,
    checkbox: Checkbox
}

class PureKMKFormTile extends Component {

    state = {
        name: '',
        fields: [],
        status: 0,
    }

    constructor(props) {
        super(props);
        this.clearFieldErrors = this.clearFieldErrors.bind(this);
        this.buildForm = this.buildForm.bind(this);
        this.fetchFormSchema();
    }

    fetchFormSchema() {
        const { form } = this.props.data;
        axios.get(configuration.API_URL + `/form/${form.schema}`)
            .then(response => {
                const { name, fields, _id } = response.data; 
                this.setState({ name, fields, _id });
            });
    }

    buildForm() {
        const { fields } = this.state;
        const fieldComponent = (type, field) => FIELDS_MAP[type] ? FIELDS_MAP[type](field) : null;

        if (!fields || fields.length === 0) return null;
        
        return fields.map((field, index) => {
            const fieldProps = { 
                ...field,
                key: index, 
                onChange: (e) => this.fieldChange(field.name, e), 
                onFocus: (name) => this.clearFieldErrors(name),
                onEnterPressed: () => this.submit() 
            };

            return fieldComponent(field.type, fieldProps);
        });
    }

    fieldChange(fieldName, event) {
        const { fields } = this.state;
        const fieldIndex = fields.findIndex(field => field.name === fieldName);
        fields[fieldIndex].value = event.target.value;
        this.setState({ fields });
    }

    submit() {
        if (this.state.status === 1) return;

        const payload = { 
            _id: this.state._id,
            fields: this.state.fields.map(field => ({ name: field.name, value: field.value || null })),
        };

        axios.post(configuration.API_URL + '/cms/form', payload)
            .then((res) => {
                EventBus.dispatchEvent(APPLICATION_EVENTS.FORM_SUBMITED, null);
                this.setState({ status: 1, postMessage: res.data.postFunction.postMessage });
            })
            .catch(e => {
                const errors = e.response.data.errorMessage;
                this.handleFormValidation(errors);
            });
    }

    handleFormValidation(errors) {
        let { fields } = this.state;
        fields = fields.map(field => {
            const { name } = field;
            const possibleError = errors[name];
            if (possibleError) return { ...field, error: errors[name] };
            return field;
        });
        this.setState({ fields });
    }

    clearFieldErrors(fieldName) {
        let clearedFields = [...this.state.fields].map(field => {
            if (field.name === fieldName && field.error) return omit(field, ['error']);
            return field;
        });
        this.setState({ fields: clearedFields });
    }

    render() {
        const submitted = this.state.status === 1;
        return (
            <>
                <h2>{this.state.name}</h2>
                {this.buildForm()}
                {this.state.postMessage && <p className='kmk-form-post-message'>{this.state.postMessage}</p>}
                <Ripples>
                    <button 
                        onClick={() => this.submit()} 
                        className={`kmk-form-submit-button ${submitted ? 'submitted' : ''} `}
                    >
                        {submitted ? <img className='check' src={Check} alt="check" /> : <span>Wyślij</span> }
                    </button>
                </Ripples>
            </>
        )
    }
}

export const KMKFormTile = UIComponent(PureKMKFormTile, 'kmk-form-tile', ['form']);
