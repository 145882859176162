import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import './another-floor-blocks.css';
import LayoutBuilder from "../../layout-builder/layout-builder";

class PureAFHeaderBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {
                dots: true,
                arrows: false,
                infinite: true,
                autoplay: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    }

    renderImageContainer() {
        if (!this.props.data.images) { return null; }
        return (<>{LayoutBuilder.getStyledImage(this.props.data.images.images)}</>);
    }

    render() {
        return (
            <div className='af-header-background'>
                {LayoutBuilder.getStyledImage(this.props.data.background.images)}
                <div className='text-container'>
                    {LayoutBuilder.buildH1(this.props.data.h2)}
                    {LayoutBuilder.buildParagraph(this.props.data.paragraph)}
                    {LayoutBuilder.buildButtons(this.props.data.buttons.buttons)}
                </div>
                {this.props.data.logo && <div className='overlay-logo' style={LayoutBuilder.getStyleFromImages(this.props.data.logo)}>{LayoutBuilder.getStyledImages(this.props.data.logo.images)}</div>}
            </div>
        );
    }
}

export const AFHeader = UIComponent(PureAFHeaderBlock, COMPONENT_MAP.AFHEADER, ['background', 'buttons']);
