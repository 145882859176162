import React from 'react';
import axios from 'axios';
import { configuration } from '../../configuration/configuration';
import CloseButtonLight from '../../assets/icons/clear-button-light.svg';
import './page-popup.css';

/**
 * Page popup accepts custom data in following format:
 * {
 *      "name": string,
 *      "backgroundImage": string,
 *      "backgroundColor": string,
 *      "h2": {
 *          "color": string,
 *          "content": string
 *          "href": string
 *          "top": stirng,
 *          "left": string,
 *          "bottom": string,
 *          "right": string,
 *      },
 *      "html": {
 *          "color": string,
 *          "content": string
 *          "href": string
 *          "top": stirng,
 *          "left": string,
 *          "bottom": string,
 *          "right": string,
 *      },
 *      "button": {
 *          "color": string,
 *          "backgroundColor": string,
 *          "content": string
 *          "href": string
 *          "top": stirng,
 *          "left": string,
 *          "bottom": string,
 *          "right": string,
 *      }
 * }
 */
export class PagePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            visible: true,
        };
    }

    componentWillMount() {
        this.fetchPolicyBlock();
    }

    fetchPolicyBlock() {
        setTimeout(() => {
            if (!window.page_init_popup) { return; }

            const { component } = window['page_init_popup'];
            axios.get(`${configuration.BLOCKS_URL}?name=${component}`)
                .then(
                    res => {
                        try {
                            const isDismissed = !!localStorage.getItem(res.data.fields.name);
                            this.setState({ fields: res.data.fields, visible: !isDismissed });                            
                        } catch(e) {}
                    }
                );
        }, 1500);
    }

    getElementStyle(el) {
        if (!el) { return null; }
        const mobile = window.innerWidth <= 768;
        return {
            ...el, 
            bottom: (mobile ? (el.bottom_m || el.bottom) : el.bottom), 
            left: (mobile ? (el.left_m || el.left) : el.left), 
        };
    }

    closePopup() {
        localStorage.setItem(this.state.fields.name, true);
        this.setState({ visible: false });
    }

    render() {
        if (Object.keys(this.state.fields).length === 0 || !this.state.visible) { return null; }

        const background = window.innerWidth <= 768 ? (this.state.fields.backgroundImageMobile || this.state.fields.backgroundImage) : this.state.fields.backgroundImage;
        const style = {
            backgroundImage: `url(${configuration.STATICS_URL}${background})`,
            width: this.state.fields.width,
            height: this.state.fields.height,
        };
        const buttonStyle = this.getElementStyle(this.state.fields.button);
        const h2Style = this.getElementStyle(this.state.fields.h2);
        const htmlStyle = this.getElementStyle(this.state.fields.html);

        return (
            <>
                <div onClick={() => this.closePopup()} className='overlay'></div>
                <div style={style} className='page-popup-container'>
                    <img onClick={() => this.closePopup()} className='close-popup' src={CloseButtonLight} alt="close-button"/>
                    {this.state.fields.h2 && (<h2 style={h2Style}>{this.state.fields.h2.content}</h2>)}
                    {this.state.fields.button && (
                        <button style={buttonStyle}>
                            <a href={this.state.fields.button.href} rel="noopener noreferrer" target="_blank">
                                {this.state.fields.button.content}
                            </a>
                        </button>
                    )}
                    {this.state.fields.html && (<p style={htmlStyle} dangerouslySetInnerHTML={{ __html: this.state.fields.html.content}}></p>)}
                </div>
            </>
        );
    }
}