import React, { PureComponent } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import LayoutBuilder from '../layout-builder/layout-builder';
import './search-page.css';


export class LatestArticleTile extends PureComponent {

    render() {
      const style = { margin: '0 15px 0 15px' };
      return (
        <a 
            href={this.props.endpoint}
            className='latest-article-tile' 
            style={(this.props.index % 2 === 1) ? style : {} } 
        >
          <div className='image-container'>
            {LayoutBuilder.getStyledImages([{ source: this.props.src, alt: '' }])}
          </div>
          <div className='text-container'>
            <h2 className='latest-article-tile-h2' title={this.props.excerpt}>
                <LinesEllipsis
                    text={this.props.title}
                    maxLine='3'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                />
            </h2>
            {this.props.excerpt && 
                <LinesEllipsis
                    title={this.props.excerpt}
                    text={this.props.excerpt}
                    maxLine='6'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                />
            }
            <div className='article-details'>
                <span className='author'>{this.props.author.name} {this.props.author.surname}</span>
                {this.props.tagList && this.props.tagList.map(t => (
                    <span className='tag'>#{t.toUpperCase()}</span>
                ))}
                {this.props.published && <span className='date'>{this.props.published}</span>}
            </div>
          </div>
        </a>
      )
    }
}
