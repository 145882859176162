import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import {DECLARE_HEIGHT} from "../store/actions";
import {connect} from "react-redux";
import uuid from 'uuid';

export default function UIComponent(CMSComponent, componentName, requiredProps, explicitCheck = true) {
    const mapDispatchToProps = dispatch => ({ declareHeight: (section) => dispatch({ type: DECLARE_HEIGHT, section })});
    const mapStateToProps = (state, props) => ({ ...props, ...state });

    class PureUIComponent extends React.PureComponent {

        constructor(props) {
            super(props);
            this.componentRef = React.createRef();
            this.state = {
                uuid: uuid(),
                canRender: this.canRender(),
                componentClass: componentName + LayoutBuilder.getLayoutProperties(props.data.options) + LayoutBuilder.getCustomClass(props.data.class),
                componentStyle: LayoutBuilder.getComponentStyle(props.data.options),
            }
        }

        canRender() {
            if (!requiredProps) return false;
            if (!!!requiredProps.length) return true;
            if (explicitCheck) {
                return requiredProps.every(requiredComponentProp => {
                    const dataProp = this.props.data[requiredComponentProp];
                    if (!dataProp) return false;
                    return Object.keys(dataProp).length > 0;
                });
            } else {
                return requiredProps.some(requiredComponentProp => {
                    const dataProp = this.props.data[requiredComponentProp];
                    if (!dataProp) return true;
                    return Object.keys(dataProp).length > 0;
                });
            }
        }

        componentDidMount() {
            const getBackgroundColor = (c) => {
                if (c === '') { return [255,255,255]; }
                return c.replace(/(rgb\()|(\))/g, '')
                    .split(',')
                    .map(v => parseInt(v));
            };

            setTimeout(() => {
                try {
                    const height = this.componentRef.current.getBoundingClientRect().top;
                    let backgroundColor = getBackgroundColor(this.componentRef.current.style.backgroundColor);
                    const section = {
                        id: this.state.uuid,
                        height,
                        backgroundColor
                    };

                    this.props.declareHeight(section);
                } catch (e) {}
            }, 200);
        }

        render() {
            return (
                this.state.canRender && 
                    <div
                        ref={this.componentRef}
                        id={this.props.data.id || this.props.data.anchor}
                        className={this.state.componentClass} 
                        style={this.state.componentStyle}>
                            <CMSComponent {...this.props} />
                    </div>
            );
        }
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(PureUIComponent);
}
