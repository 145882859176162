import React from 'react';
import './cta-carousel.css';
import { configuration } from '../../../configuration/configuration';
import EventBus from '../../../event-bus/event-bus';

class CTACarousel extends React.Component {

    static defaultProps = {
        carouselInterval: 2000,
        height: '500px',
        textColor: '#FFF',
        highlightColor: '#EDCD06',
        backgroundColor: '#FFF',
        buttons: [],
        images: [],
        buttonColor: '#EDCD06',
        buttonTextColor: '#FFF',
    };

    state = {
        carouselIndex: 0,
        carouselLength: 0,
    };

    constructor(props) {
        super(props);
        this.imageSectionRef = React.createRef();
        this.state = {
            carouselIndex: 0,
            carouselLength: props.images.length || 0,
            images: props.images,
            sectionWidth: window.innerWidth / 2,
        }
    }

    componentDidMount() {
        this.setImageSection();
        this.startCarousel();
    }

    getSectionWidth() {
        return this.imageSectionRef.current.offsetWidth ;
    }

    incrementIndex() {
        const nextIndex = (this.state.carouselIndex + 1) % this.state.carouselLength;
        this.setState({ carouselIndex: nextIndex });
    }

    setIndex(index) {
        if (index > this.state.carouselLength) return;
        this.setState({ carouselIndex: index }, () => {
            clearInterval(this.interval);
            this.setCarouselTranslation();
            this.startCarousel();
        });
    }

    startCarousel() {
        this.interval = setInterval(() => {
            this.incrementIndex();
            this.setCarouselTranslation();
        }, this.props.carouselInterval);
    }

    setCarouselTranslation() {
        const { carouselIndex, sectionWidth } = this.state;
        const imageStrip = this.imageSectionRef.current.firstChild;
        const translation = carouselIndex * sectionWidth;
        imageStrip.style.transform = `translate(-${translation}px)`;
    }

    setImageSection() {
        const { images } = this.props;
        const imagesRefs = [];
        const sectionWidth = this.getSectionWidth();
        this.setState({ sectionWidth });
        const carouselImages = images.map((image, index) => {
            const imageSrc = `${configuration.STATICS_URL}${image}`;
            const ref = React.createRef();
            imagesRefs.push(ref);

            return (
                <img 
                    ref={ref}
                    key={index} 
                    src={imageSrc} 
                    style={{ width: `${sectionWidth}px`}}
                    alt={`carousel-tile-${index}`} 
                />
            );
        });

        this.setState({
            carouselImages,
            carouselImagesRefs: imagesRefs,
        });
    }

    renderButtons() {
        const { buttons } = this.props;
        return buttons.map((button, index) => {
            const { label, eventName, eventData, color, textColor } = button;
            return (
                <button 
                    key={index}
                    onClick={() => EventBus.dispatchEvent(eventName, eventData)}
                    style={{
                        color: textColor || this.props.buttonTextColor,
                        backgroundColor: color || this.props.buttonColor,
                    }}
                >{label}</button>
            );
        });
    }

    renderTextCarouselContent() {
        const { text, textColor, highlightColor, fontSize } = this.props;
        return text.map((header, index) => {
            const isHighlighted = index === this.state.carouselIndex;
            return (
                <h2
                    onClick={() => this.setIndex(index)}
                    style={{ 
                        color: isHighlighted ? highlightColor : textColor,
                        fontSize: fontSize || ''
                    }}
                    key={Math.random()} 
                    data-highlighted={isHighlighted}
                >{header}</h2>
            );
        });
    }

    renderTextSection() {
        const { additionalText, additionalTextFontSize, background, backgroundColor } = this.props;
        return (
            <div 
                className='centered-section' 
                style={{ 
                    backgroundImage: `url(${configuration.STATICS_URL}${background})`,
                    backgroundColor
                }}
            >
                <div>   
                    <p style={{ fontSize: additionalTextFontSize }} className='cta-carousel-additionaltext'>{additionalText}</p>
                    {this.renderTextCarouselContent()}
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

    renderImageSection() {
        return this.state.carouselImages;
    }

    render() {
        if (!this.props || Object.keys(this.props).length === 0) return null;
        const { height } = this.props;

        return (
            <div className='cta-carousel-container'>
                <div style={{height: height}} className='cta-carousel-section text-section'>
                    {this.renderTextSection()}
                </div>
                <div style={{height: height}} className='cta-carousel-section image-section' ref={this.imageSectionRef}>
                    <div className='images-strip'>{this.renderImageSection()}</div>
                </div>
            </div>
        );
    }
}

export default CTACarousel;
