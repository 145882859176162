import React from 'react';
import { COMPONENT_MAP } from './component-map';
import { KMKTitleTile } from '../ui-components/kmk-title-tile';
import { KMKTextTile } from '../ui-components/kmk-text-tile';
import { KMKListTile } from '../ui-components/kmk-list-tile';
import { KMKMediaLinkTile } from '../ui-components/kmk-media-link-tile';
import { KMKImageTextTile } from '../ui-components/kmk-image-text-tile';
import { KMKLinkEmbededTile } from '../ui-components/kmk-link-embeded-tile';
import { KMKPeopleTile } from '../ui-components/kmk-people-tile';
import { KMKContactTile } from '../ui-components/kmk-contact-tile';
import { KMKCarouselTile } from '../ui-components/kmk-carousel-tile';
import { KMKVideoTile } from '../ui-components/kmk-video-tile';
import { KMKCTATile } from '../ui-components/kmk-cta-tile';
import { KMKFooterTile } from '../ui-components/kmk-footer-tile';
import { KMKMapTile } from '../ui-components/kmk-map-tile';
import { KMKCardTile } from '../ui-components/kmk-card-tile';
import KMKBlogTile from '../ui-components/kmk-blog-component';
import KMKImageTile from '../ui-components/kmk-image-tile';
import { KMKSummaryTile } from '../ui-components/kmk-summary-tile';
import { KMKFormTile } from '../ui-components/kmk-form-tile';
import { KMKCustomTile } from '../ui-components/kmk-custom-tile';
import { KMKButtonsTile } from '../ui-components/kmk-buttons-tile';
import { KMKTableOfContents } from '../ui-components/kmk-table-of-contents';
import { KMKInstagramFeed } from '../ui-components/kmk-instagram-feed';
import { KMKAppointlyTile } from '../ui-components/kmk-appointly-tile';
import { KMKFooterLP } from "../ui-components/kmk-footer-lp";
import { AFHeader } from "../ui-components/another-floor-blocks/af-header-block";
import { AFWorldWide } from "../ui-components/another-floor-blocks/af-worldwide-block";
import { AFCard } from "../ui-components/another-floor-blocks/af-card-block";
import { AFCTA } from "../ui-components/another-floor-blocks/af-cta-block";
import { AFHTML } from "../ui-components/another-floor-blocks/af-html-block";
import { AFFooter } from "../ui-components/another-floor-blocks/af-footer";
import { AFText } from "../ui-components/another-floor-blocks/af-text";
import { AFButton } from "../ui-components/another-floor-blocks/af-button";
import { AFCTABackground } from "../ui-components/another-floor-blocks/af-cta-background";
import { AFTimer } from "../ui-components/another-floor-blocks/af-timer";
import { AFPersons } from "../ui-components/another-floor-blocks/af-persons";
import {AFTextCarousel} from "../ui-components/another-floor-blocks/af-text-carousel";
import {AFVideoBanner} from "../ui-components/another-floor-blocks/af-video-banner";
import {AFResponsiveBanner} from "../ui-components/another-floor-blocks/af-responsive-banner";
import {AFImageCarousel} from "../ui-components/another-floor-blocks/af-image-carousel";
import {AFHeaderHover} from "../ui-components/another-floor-blocks/af-header-hover-block";
import AFColumnHtml from "../ui-components/another-floor-blocks/af-column-html";
import AFImageTransition from "../ui-components/another-floor-blocks/af-image-transition";
import AFBooklet from "../ui-components/another-floor-blocks/af-booklet-block";
import AFVideo from "../ui-components/another-floor-blocks/af-video";

export class PageBuilder {

    constructor() {
        this.buildPage = this.buildPage.bind(this);
        this.getComponent = this.getComponent.bind(this);
    }

    buildPage(sections) {
        return sections.map((section, i) => this.getComponent(section, i));
    }

    isScrolledThemeIsDisabled(firstSection) {
        return firstSection.component !== COMPONENT_MAP.KMKTITLETILE;
    }

    hasFooter(sections) {
        return sections.findIndex( s => s.component === COMPONENT_MAP.KMKFOOTERTILE ) > -1 ? true : false;
    }

    getComponent(component, key) {
        switch (component.component) {
            case COMPONENT_MAP.AFHEADER: return <AFHeader key={key} data={ component } />;
            case COMPONENT_MAP.AFHEADERHOVER: return <AFHeaderHover key={key} data={ component } />;
            case COMPONENT_MAP.AFWORLDWIDE: return <AFWorldWide key={key} data={ component } />;
            case COMPONENT_MAP.AFCARD: return <AFCard key={key} data={ component } />;
            case COMPONENT_MAP.AFCTA: return <AFCTA key={key} data={ component } />;
            case COMPONENT_MAP.AFHTML: return <AFHTML key={key} data={ component } />;
            case COMPONENT_MAP.AFFOOTER: return <AFFooter key={key} data={ component } />;
            case COMPONENT_MAP.AFTEXT: return <AFText key={key} data={ component } />;
            case COMPONENT_MAP.AFBUTTON: return <AFButton key={key} data={ component } />;
            case COMPONENT_MAP.AFCTABACKGROUND: return <AFCTABackground key={key} data={ component } />;
            case COMPONENT_MAP.AFTIMER: return <AFTimer key={key} data={ component } />;
            case COMPONENT_MAP.AFPERSONS: return <AFPersons key={key} data={ component } />;
            case COMPONENT_MAP.AFVIDEOBANNER: return <AFVideoBanner key={key} data={component} />;
            case COMPONENT_MAP.KMKTITLETILE: return <KMKTitleTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKTEXTTILE: return <KMKTextTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKIMAGETILE: return <KMKImageTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKLISTTILE: return <KMKListTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKMEDIALINKTILE: return <KMKMediaLinkTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKIMAGETEXTTILE: return <KMKImageTextTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKLINKEMBEDEDTILE: return <KMKLinkEmbededTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKPEOPLETILE: return <KMKPeopleTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKCONTACTTILE: return <KMKContactTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKCAROUSELTILE: return <KMKCarouselTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKVIDEOTILE: return <KMKVideoTile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKCTATILE: return <KMKCTATile key={ key } data={ component }/>;
            case COMPONENT_MAP.KMKFOOTERTILE: return <KMKFooterTile key={ key }/>;
            case COMPONENT_MAP.KMKMAPTILE: return <KMKMapTile key={key} data={ component }/>;
            case COMPONENT_MAP.KMKCARDTILE: return <KMKCardTile key={key} data={ component }/>;
            case COMPONENT_MAP.KMKBLOGTILE: return <KMKBlogTile key={key} data={ component }/>;
            case COMPONENT_MAP.KMKSUMMARYTILE: return <KMKSummaryTile key={key} data={ component } />;
            case COMPONENT_MAP.KMKFORMTILE: return <KMKFormTile key={key} data={ component } />;
            case COMPONENT_MAP.KMKCUSTOMTILE: return <KMKCustomTile key={key} data={ component } />;
            case COMPONENT_MAP.KMKBUTTONSTILE: return <KMKButtonsTile key={key} data={ component } />;
            case COMPONENT_MAP.KMKTABLEOFCONTENTS: return <KMKTableOfContents key={key} data={ component } />;
            case COMPONENT_MAP.KMKINSTAGRAMFEED: return <KMKInstagramFeed key={key} data={ component } />;
            case COMPONENT_MAP.KMKAPPOINTLYTILE: return <KMKAppointlyTile key={key} data={ component } />;
            case COMPONENT_MAP.KMKFOOTERLP: return <KMKFooterLP key={key} data={ component } />;
            case COMPONENT_MAP.AFTEXTCAROUSEL: return <AFTextCarousel key={key} data={ component } />;
            case COMPONENT_MAP.AFMAP: return <KMKMapTile key={key} data={component} />
            case COMPONENT_MAP.AFRESPONSIVEBANNER: return <AFResponsiveBanner key={key} data={ component } />;
            case COMPONENT_MAP.AFIMAGECAROUSEL: return <AFImageCarousel key={key} data={ component } />;
            case COMPONENT_MAP.AFCOLUMNHTML: return <AFColumnHtml key={key} data={ component } />;
            case COMPONENT_MAP.AFIMAGETRANSITION: return <AFImageTransition key={key} data={ component } />;
            case COMPONENT_MAP.AFBOOKLET: return <AFBooklet key={key} data={ component } />;
            case COMPONENT_MAP.AFVIDEO: return <AFVideo key={key} data={ component } />;
            default: return null;
        }
    }


}
