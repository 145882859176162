import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import {LazyLoadComponent} from "react-lazy-load-image-component";

class PureAFTextCarousel extends React.PureComponent {

    constructor(props) {
        super(props);
        this.cardRefs = [];
        this.listRef = React.createRef();
        this.state = {
            cards: [],
            currentCard: 0
        };
        this.adjustListContainerHeight = this.adjustListContainerHeight.bind(this);
    }

    componentDidMount() {
        this.parseCardData();
        this.nextCard();
    }

    parseCardData() {
        try {
            const data = this.props.data.cards.data;
            const parsedCustom = JSON.parse(data);
            this.setState({
                cards: parsedCustom.hasOwnProperty('cards') ? parsedCustom.cards : [],
                currentCard: 0,
                animate: true
            }, () => {
                this.cardRefs = this.state.cards.map(() => React.createRef());
            });
        } catch (e) {}
    }

    nextCard() {
        const interval = setInterval(() => {
            const nextIndex = (this.state.currentCard + 1) % this.state.cards.length;
            this.setState({currentCard: nextIndex});
            this.adjustListContainerHeight(nextIndex);
        }, 5000);

        this.setState({ interval });
    }

    getTextColor() {
        if (!this.props.data.htmlContent || !this.props.data.htmlContent.html) { return '#000'; }
        const match = this.props.data.htmlContent.html.match(/color:#[a-zA-Z0-9]+/gi) || [];
        return (!!match.length) ? match[0].split(':')[1] : '#000';
    }

    adjustListContainerHeight(cardIndex) {
        try {
            this.listRef.current.style.height = `${this.cardRefs[cardIndex].current.getBoundingClientRect().height}px`;
        } catch (e) {}
    }

    render() {
        if (!this.state.cards.length) { return null; }
        return (
            <LazyLoadComponent threshold={100}>
                <div className='animated-content'>
                    <div className='html-content-container' dangerouslySetInnerHTML={{ __html: this.props.data.htmlContent.html}}></div>
                    <div className='carousel-container'>
                        <ul ref={this.listRef}>
                            {this.state.cards.map((card, i) => {
                                return (
                                    <li
                                        key={i}
                                        ref={this.cardRefs[i]}
                                        data-active={i===this.state.currentCard}
                                        style={{ color: this.getTextColor() }}
                                        dangerouslySetInnerHTML={{ __html: card}}
                                    >
                                    </li>)
                            })}
                        </ul>
                    </div>
                </div>
            </LazyLoadComponent>
        );
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }
}

export const AFTextCarousel = UIComponent(PureAFTextCarousel, COMPONENT_MAP.AFTEXTCAROUSEL, []);
