import './hamburger.css';
import React from 'react';

function DumbHamburger(props) {
    let component_class = 'menu-toggle';
    if (props.active) { component_class += ' open'; }
    if (props.inverted === '#000') { component_class += ' inverted'; }
    return (
            <div className={ component_class } onClick={ props.onClick }>
                <div className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="cross">
                    <span></span>
                    <span></span>
                </div>
            </div>
    );
}

const Hamburger = DumbHamburger;
export default Hamburger;
