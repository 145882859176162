import React, { PureComponent } from 'react';
import UIComponent from '../hoc/ui-component';

class PureAppointlyTile extends PureComponent {
    render() {
        return (<div dangerouslySetInnerHTML={{ __html: this.props.data.appointly.iframe }}></div>);
    }
}

export const KMKAppointlyTile = UIComponent(PureAppointlyTile, 'kmk-appointly-tile', ['appointly']);
