import React from 'react';

function Checkbox(props) {
    const { label, name, error } = props;
    let hasError = error && error.length > 0;
    return (
        <label 
            aria-label={name}
            htmlFor={name}
            style={props.style} 
            className={`kmk-checkbox ${hasError ? 'has-error' : ''}`}
        >
            {typeof label === 'string' && <div className={'kmk-checkbox-label'} dangerouslySetInnerHTML={{ __html: label }}></div>}
            {typeof label === 'object' && label}
            <input
                value="false"
                name={name}
                id={name}
                type="checkbox"
                onChange={(value) => {
                    const { checked } = value.target;
                    if (!props.onChange) return;
                    if (props.onFocus) props.onFocus();
                    props.onChange({ target: { value: checked }})
                }}
            />
            <span></span>
        </label>
    );
}

export default Checkbox;
