import React from 'react';
import { Link } from 'react-router-dom';

export function SVGLogo(props) {
    return (
        <svg 
            onClick={ props.onClick } 
            style={ props.logoStyle } 
            xmlns="http://www.w3.org/2000/svg" 
            width="73px" 
            height="73px" 
            viewBox={`0 0 ${props.logoStyle.width.replace(/px/gi, '')} ${props.logoStyle.height.replace(/px/gi, '')}`}
        >
            <g>
                <g>
                    <polygon points="2.2,0 2.2,11.5 0.1,12.7 0.1,18.8 2.2,17.6 2.2,24.1 16.5,24.1 16.5,18.4 8.1,18.4 8.1,14.1 12.1,11.7 12.1,5.6 
                        8.1,8 8.1,0"/>
                    <path d="M29.4,7.3l2.4,7.6h-4.8L29.4,7.3z M25.2,20.4h8.4l1.2,3.7h1.3c-1,1-1.7,2.4-1.8,3.9c0,2,1.4,3.3,3.6,3.3
                        c1,0,2.1-0.2,3.1-0.6l0.2-0.1v-3.5l-0.3,0.1c-0.2,0.1-0.5,0.1-0.7,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.5
                        c0.2-1,0.7-2,1.4-2.7l0.1-0.1L33,0h-7.3l-8.2,24.1H24L25.2,20.4z"/>
                    <polygon points="55.3,0 48.5,9.7 48.5,0 42.6,0 42.6,24.1 48.5,24.1 48.5,13.8 55.6,24 55.7,24.1 62.4,24.1 54,11.8 62.1,0"/>
                    <rect x="63.9" y="0" width="5.9" height="24.1"/>
                    <polygon points="12.7,32.6 5.9,42.3 5.9,32.6 0,32.6 0,56.7 5.9,56.7 5.9,46.4 13,56.6 13,56.7 19.8,56.7 11.3,44.4 19.5,32.6"/>
                    <polygon points="47.5,32.6 43.7,48.4 39.3,32.6 34.6,32.6 30.2,48.4 26.4,32.6 20.2,32.6 26.8,56.7 33.3,56.7 36.9,43.5 40.6,56.7 47.1,56.7 53.7,32.6"/>
                    <rect x="55.7" y="32.6" width="5.9" height="24.1"/>
                    <polygon points="65.1,32.6 65.1,56.7 80.4,56.7 80.4,51 71,51 71,47.3 79.4,47.3 79.4,41.7 71,41.7 71,38.3 80.2,38.3 80.2,32.6"/>
                    <polygon points="82.2,32.6 82.2,38.3 88.2,38.3 88.2,56.7 94.1,56.7 94.1,38.3 100.2,38.3 100.2,32.6"/>
                    <polygon points="114.7,32.6 114.7,44.9 106,32.6 101.6,32.6 101.6,56.7 107.5,56.7 107.5,44.5 116.2,56.7 120.6,56.7 120.6,32.6"/>
                    <polygon points="122.5,32.6 122.5,56.7 137.8,56.7 137.8,51 128.4,51 128.4,47.3 136.8,47.3 136.8,41.7 128.4,41.7 128.4,38.3 
                        137.7,38.3 137.7,32.6"/>
                </g>
            </g>
            </svg>
    );
}

export function SVGLogoHorizontal(props) {
    return (
        <svg 
            onClick={ props.onClick } 
            style={ props.logoStyle } 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox={`0 0 240 40`}
            preserveAspectRatio="xMidYMin meet"
        >
            <defs>
                <polygon points="0 34 234 34 234 0.691 0 0.691"></polygon>
            </defs>
            <g stroke="none" strokeWidth="1" fill="inherit" fillRule="evenodd">
                <g transform="translate(-71.000000, -5859.000000)">
                    <g transform="translate(71.000000, 5858.000000)">
                        <polygon points="2.151 0.9996 2.151 13.2346 0 14.5246 0 21.0026 2.151 19.7136 2.151 26.6376 17.393 26.6376 17.393 20.5726 8.431 20.5726 8.431 15.9796 12.734 13.3996 12.734 6.9206 8.431 9.5006 8.431 0.9996"></polygon>
                        <g transform="translate(0.000000, 0.308600)">
                            <path d="M31.0571,8.4228 L33.6231,16.4648 L28.4911,16.4648 L31.0571,8.4228 Z M26.5971,22.3858 L35.5181,22.3858 L36.7731,26.3288 L38.1841,26.3288 C36.9221,27.7838 36.2341,29.2378 36.2341,30.4668 C36.2341,32.6468 37.7111,33.9998 40.0911,33.9998 C41.3051,33.9998 42.2481,33.8188 43.3471,33.3688 L43.5171,33.2998 L43.5171,29.5138 L43.1581,29.6328 C42.9261,29.7108 42.6381,29.7958 42.4211,29.7618 C42.2501,29.7618 42.1211,29.7168 42.0361,29.6258 C41.9361,29.5198 41.8901,29.3448 41.9051,29.1038 C41.9051,28.3868 42.4951,27.2898 43.4461,26.2398 L43.5551,26.1208 L34.9101,0.6918 L27.2061,0.6918 L18.4891,26.3288 L25.3411,26.3288 L26.5971,22.3858 Z" id="Fill-2"></path>
                            <polygon points="65.8594 0.6914 58.6724 0.6914 51.4164 11.0014 51.4164 0.6914 45.1364 0.6914 45.1364 26.3294 51.4164 26.3294 51.4164 15.3164 58.9494 26.2124 59.0304 26.3294 66.2134 26.3294 57.2104 13.1884"></polygon>
                            <polygon mask="url(#mask-2)" points="67.763 26.329 74.044 26.329 74.044 0.691 67.763 0.691"></polygon>
                            <polygon mask="url(#mask-2)" points="107.644 0.7363 100.475 0.7363 93.239 11.0173 93.239 0.7363 86.973 0.7363 86.973 26.3083 93.239 26.3083 93.239 15.3263 100.75 26.1913 100.832 26.3083 107.998 26.3083 99.017 13.2023"></polygon>
                            <polygon mask="url(#mask-2)" points="133.3745 17.4492 128.7705 0.7362 123.7075 0.7362 119.1035 17.4492 114.9765 0.7362 108.3995 0.7362 115.4485 26.3082 122.3715 26.3082 126.2395 12.2962 130.1065 26.3082 137.0285 26.3082 144.0795 0.7362 137.5015 0.7362"></polygon>
                            <polygon mask="url(#mask-2)" points="146.225 26.309 152.49 26.309 152.49 0.737 146.225 0.737"></polygon>
                            <polygon mask="url(#mask-2)" points="162.4399 16.3711 171.3769 16.3711 171.3769 10.3911 162.4399 10.3911 162.4399 6.7871 172.2709 6.7871 172.2709 0.7361 156.1739 0.7361 156.1739 26.3091 172.4499 26.3091 172.4499 20.2581 162.4399 20.2581"></polygon>
                            <polygon mask="url(#mask-2)" points="174.3086 6.7871 180.7436 6.7871 180.7436 26.3081 187.0096 26.3081 187.0096 6.7871 193.4436 6.7871 193.4436 0.7361 174.3086 0.7361"></polygon>
                            <polygon mask="url(#mask-2)" points="208.8931 13.7412 199.6541 0.7362 194.9511 0.7362 194.9511 26.3082 201.2161 26.3082 201.2161 13.3042 210.4561 26.3082 215.1591 26.3082 215.1591 0.7362 208.8931 0.7362"></polygon>
                            <polygon mask="url(#mask-2)" points="223.9907 20.2578 223.9907 16.3708 232.9277 16.3708 232.9277 10.3908 223.9907 10.3908 223.9907 6.7868 233.8217 6.7868 233.8217 0.7368 217.7247 0.7368 217.7247 26.3088 233.9997 26.3088 233.9997 20.2578"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function DumbKMKLogo(props) {
    const logo_style = {
        position: 'fixed',
        zIndex: 1000,
        top: '.75rem',
        left: '1rem',
        width: '120px',
        height: '50px',
        cursor: 'pointer',
        fill: props.inverted ? '#000' : '#FFF'
    }
    function handleClick() {
        console.log('Clicked logo');
    }

    return window.location.pathname !== '/' ? 
    (
        <Link to="/">
            <SVGLogo logoStyle={ logo_style } onClick={ () => handleClick }/>
        </Link>
    ) :
    (
        <SVGLogo logoStyle={ logo_style } onClick={ () => props.onClick }/>
    );
}

export const KMKLogo = DumbKMKLogo;