import {SET_PAGE_SETTINGS, SET_BLOG, DECLARE_HEIGHT} from './actions';
import { configuration } from '../configuration/configuration';

const initialState = {
    devMode: configuration.DEV_MODE,
    progressiveImageLoading: false,
    blog: null,
    sections: [],
};

export function rootReducer(state = initialState, action) {
    switch(action.type) {
        case SET_PAGE_SETTINGS: 
            return { ...action.settings };
        case SET_BLOG:
            return { ...state, blog: action.blog };
        case DECLARE_HEIGHT: {
            return { ...state, sections: [...state.sections, action.section] };
        }
        default: 
            return state;
    }
}
