import React from 'react';
import LayoutBuilder from "../../layout-builder/layout-builder";
import UIComponent from "../../hoc/ui-component";

class PureAFText extends React.PureComponent {
    render() {
        return (
            <>
                {LayoutBuilder.buildH2(this.props.data.h2)}
                {LayoutBuilder.buildParagraph(this.props.data.paragraph)}
            </>
        );
    }
}

export const AFText = UIComponent(PureAFText, 'af-text', []);
