import React from 'react';
import Checkbox from './checkbox';

function Conditions(props) {
    const { name, label, error } = props;
    if (!name) return null;
    return (
        <div key={name} onClick={() => props.onFocus(name)}>
            <Checkbox 
                aria-label={props.name}
                key={props.keys}
                error={error}
                name={name} 
                label={label} 
                onChange={(value) => props.onChange(value)}
            />
        </div>
    );
}

export default Conditions;