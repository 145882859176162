/* eslint-disable no-useless-escape */
import React from 'react';
import ProgressiveImage from 'react-progressive-image';
import { configuration } from '../../configuration/configuration';

function KMKLazyImage(props) {
    const { src, alt, style, images, className } = props;
    const innerWidth = window.innerWidth;
    const getDeviceImage = () => innerWidth >= 768 ? props.images[0] : props.images[1]; 
    const source = images && images.length > 1 ? getDeviceImage().source : src;

    const originalSrc = source.replace(/\_[sol].|\./, '_o.');
    const placeholderSrc = source.replace(/\_[sol].|\./, '_s.');
    const lazyImageClass = (className ? className : '')  + 'lazy-image';
    

    return (
        <ProgressiveImage
            placeholder={configuration.STATICS_URL+placeholderSrc}
            src={configuration.STATICS_URL+originalSrc}
        >
            {(src, loading) => (
                <img 
                    className={lazyImageClass}
                    data-loading={loading}
                    src={src} 
                    alt={alt} 
                    style={style}
                />
            )}
        </ProgressiveImage>
    );
}

export default KMKLazyImage;