import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import {configuration} from "../../configuration/configuration";

class PureAFVideo extends React.Component {

    constructor(props) {
        super(props);
        console.warn('Props: ', props);
    }

    render() {
        return (
            <div className={`af-video-wrapper wrapper-align-${this.props.data.video.align || 'left'}`}>
                <div className='video-container'>
                    <video
                        playsInline="playsinline"
                        autoPlay="autoplay"
                        muted="muted"
                        loop="loop"
                    >
                        <source src={`${configuration.ASSETS_URL}${this.props.data.video.asset}`} type="video/mp4"/>
                    </video>
                </div>
                <div className='text-container' dangerouslySetInnerHTML={{ __html: this.props.data.htmlContent.html}}></div>
            </div>
        );
    }
}


const AFVideo = UIComponent(PureAFVideo, COMPONENT_MAP.AFVIDEO, ['video']);
export default AFVideo;
