import React from 'react';
import { connect } from 'react-redux';
import { SET_BLOG } from '../store/actions';
import TopBar from '../ui-components/top-bar/top-bar';
import withCMSPage from '../hoc/cms-page';
import { PagePopup } from '../ui-components/popup/page-popup';
import CookiesBanner from "../ui-components/another-floor-blocks/af-cookies-banner";

function KMKPage(props) {

    const pageClass = `masonry-layout ${props.isBlog ? 'blog-layout' : ''}`;
    props.setBlog({...props.blog, title: props.title});

    return (
        <>
            <TopBar title={props.title} />
            <CookiesBanner />
            <PagePopup />
            <div className={pageClass}>
                {props.components}
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setBlog: (blog) => dispatch({ type: SET_BLOG, blog }),
    }
}

const StatefulPage = connect(
    null,
    mapDispatchToProps,
)(KMKPage);

const Page = withCMSPage(StatefulPage);
export default Page;
