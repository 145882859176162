import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureKMKListTile extends React.PureComponent {

    render() {
        const { h2, paragraph, list, label } = this.props.data;
        return (
            <>
                {LayoutBuilder.buildLabel(label)}
                {LayoutBuilder.buildH2(h2)}
                {LayoutBuilder.buildParagraph(paragraph)}
                {LayoutBuilder.buildList(list)}
            </>
        )
    }
}

export const KMKListTile = UIComponent(PureKMKListTile, 'kmk-list-tile', ['h2', 'list']);
