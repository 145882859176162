import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureKMKCardTile extends React.PureComponent {

    renderTextSection() {
        const { h2, paragraph } = this.props.data;
        
        return (
            <div className='card-tile-container'>
                {LayoutBuilder.buildH2(h2)}
                {LayoutBuilder.buildParagraph(paragraph)}
                {this.props.data.link && this.props.data.link.name && <a href={this.props.data.link.href}>{this.props.data.link.name}</a> }
            </div>
        )
    }

    renderImageSection(isLinked) {
        const { images } = this.props.data;
        return isLinked ? (<div className='kmk-card-tile-link-images'>{LayoutBuilder.getStyledImages(images)}</div>) : LayoutBuilder.getStyledImages(images);
    }

    render() {
        const href = this.props.data.link ? this.props.data.link.href || null : null;
        const { justifycenter } = this.props.data.options;
        return (
            <a href={href} className={`kmk-card-tile-link ${justifycenter ? 'center-container': ''}`}>
                {this.renderImageSection(!!href)}
                {this.renderTextSection()}
            </a>
        );
    }
}

export const KMKCardTile = UIComponent(PureKMKCardTile, 'kmk-card-tile', ['h2', 'paragraph', 'images'], false);
