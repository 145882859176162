import React from 'react';
import useCMSBlock from "../../hoc/use-cms-block";
import {SVGLogo} from "../logo/svg-logo";
import axios from "axios";
import {configuration} from "../../configuration/configuration";
import {ReactiveForm} from "../../form/reactive-form";

class PureAFFooter extends React.PureComponent {

    submitForm() {
        if (this.state.postMessage) return;
        const payload = {
            fields: [
                {
                    name: 'email',
                    value: this.state.email,
                },
                {
                    name: 'message',
                    value: this.state.message,
                },
                {
                    name: 'conditions',
                    value: this.state.newsletterConditions
                }
            ],
            _id: this.state.formSchema
        };

        axios.post(configuration.API_URL + '/cms/form', payload)
            .then(response => {
                this.setState({ postMessage: response.data.postFunction.postMessage });
            })
            .catch(error => {
                if (!error || !error.response) return;
                const errors = error.response.data.errorMessage;
                this.setState({ error: errors });
            });
    }

    render() {
        return (
            <footer id='footer' className='af-footer'>
                <div className='footer-section'>
                    <SVGLogo className='footer-svg-logo' contrast='#FFF'/>
                </div>
                <div className='footer-section grow-1'>
                    <label className='footer-section-contact-header'>{this.props.data.footer_label}</label>
                    <p dangerouslySetInnerHTML={{__html: this.props.data.footer_contact}}></p>
                    <div className='icons-container-wrapper'>
                        <label>{this.props.data.footer_follow.label}</label>
                        <div className='icons-container'>
                            {this.props.data.footer_follow.icons.map((i, index) => (<a key={index} href={i.href} target="_blank" rel="noopener noreferrer" style={{ backgroundImage: `url(${i.icon})` }}></a>) )}
                        </div>
                    </div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className='autodesk-link'
                        href={this.props.data.footer_autodesk_href}
                    >{this.props.data.footer_autodesk_label}</a>
                </div>
                <div className='footer-section grow-3'>
                    <div className='form-container-wrapper'>
                        <div className='form-desc'>{this.props.data.footer_form.desc}</div>
                        <ReactiveForm
                            formSchema={this.props.data.footer_form.formSchema}
                            sendButtonLabel='Send'
                            lang='en'
                        />
                    </div>
                </div>
            </footer>
        );
    }
}

export const AFFooter = useCMSBlock(PureAFFooter, 'af-footer');
