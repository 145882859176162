export const SET_PAGE_SETTINGS = 'SET_PAGE_SETTINGS';
export function setPageSettings(settings) {
    return {
        type: SET_PAGE_SETTINGS,
        settings,
    }
}

export const SET_BLOG = 'SET_BLOG';
export function setBlog(blog) {
    return {
        type: SET_BLOG,
        blog,
    }
}

export const DECLARE_HEIGHT = 'DECLARE_HEIGHT';
export function declareHeight(section) {
    return {
        type: DECLARE_HEIGHT,
        section,
    };
}
