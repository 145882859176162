import React from 'react';
import LayoutBuilder from "../../layout-builder/layout-builder";
import UIComponent from "../../hoc/ui-component";

class PureAFResponsiveBanner extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { image: null };
        this.trackResizeEvent = this.trackResizeEvent.bind(this);
    }

    setResponsiveImage(images) {
        if (window.innerWidth <= 768) {
            this.setState({ image: images.length > 1 ? images[1] : images[0] });
        } else {
            this.setState({ image: images[0] });
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.trackResizeEvent);
        this.trackResizeEvent();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.trackResizeEvent);
    }

    trackResizeEvent() {
        const images = this.props.data.images.images || this.props.data.images;
        const styledImages = LayoutBuilder.getStyledImages(images);
        this.setResponsiveImage(styledImages)
    }

    render() {
        return (
            <>{this.state.image}</>
        )
    }
}

export const AFResponsiveBanner = UIComponent(PureAFResponsiveBanner, 'af-responsive-banner', ['images']);
