import { configuration } from '../configuration/configuration';

export const RWD = {
    mobile: {
        maxWidth: 768,
        font: 14,
        prefix: '_o'
    },
    tablet: {
        maxWidth: 1024, 
        font: 14,
        prefix: '_o'
    },
    desktop: {
        font: 16,
        prefix: '_o'
    }
};

export function getPrefix() {
    const width = window.innerWidth;
    if (width < RWD.mobile.maxWidth) return RWD.mobile.prefix;
    if (width < RWD.tablet.maxWidth) return RWD.tablet.prefix;
    return RWD.desktop.prefix;
}
export function getOriginalSrc(original) {
    return `${configuration.STATICS_URL}${original}`;
}
export function getCachedOriginalSrc(original) {
    return `${configuration.STATICS_URL}${original.replace(/\./gi, `_o.`)}`;
}
export function getSrcForRwd(original) {
    return `${configuration.STATICS_URL}${original.replace(/\./gi, `${getPrefix()}.`)}`;
}
export function getSrcForThumbnail(original) {
    return `${configuration.STATICS_URL}${original.replace(/\./gi, `_s.`)}`;
}
