import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import LayoutBuilder from "../../layout-builder/layout-builder";
import {LazyLoadComponent} from "react-lazy-load-image-component";

class PureAFPersons extends React.PureComponent {
    render() {
        return (
            <LazyLoadComponent>
                <div className='animated-content'>
                    {LayoutBuilder.getStyledImages(this.props.data.images.images)}
                    {this.props.data.htmlContent && <div
                        className='af-persons-html-content'
                        dangerouslySetInnerHTML={{ __html: this.props.data.htmlContent.html}}
                    ></div>}
                </div>
            </LazyLoadComponent>
        )
    }
}

export const AFPersons = UIComponent(PureAFPersons, COMPONENT_MAP.AFPERSONS, []);
