import React from 'react';
import axios from 'axios';
import { configuration } from '../../../configuration/configuration';
import './cookies-banner.css';

class CookiesBanner extends React.PureComponent {

    constructor(props) {
        super(props);
        try {
            const userAgent = navigator.userAgent;
            const isCrawler = userAgent === 'ReactSnap';
            const accepted = isCrawler ? true : (localStorage.getItem('cookies-accepted') || false);
            if (!accepted) { this.fetchComponentData(); }
            
            this.state = {
                accepted,
                visible: true,
            }
        } catch(e) {
            this.state = {
                accepted: true,
                visible: false,
            }
        }
    }

    fetchComponentData() {
        axios.get(configuration.BLOCKS_URL + '?name=CookieBanner')
            .then(response => {
                const { fields } = response.data;
                this.setState({ fields });
            })
            .catch(() => this.setState({ fields: null }));
    }

    acceptCookies() {
        try {
            this.setState({ visible: false });
            setTimeout(() => this.setState({ accepted: true }), 450);
            localStorage.setItem('cookies-accepted', true);
        } catch(e) {};
    }

    render() {
        const bannerClass = `cookies-banner ${this.state.visible ? 'visible' : ''}`;
        if (!this.state.fields || this.state.accepted) { return null; }

        return (
            <div className={bannerClass}>
                <div className='policy-label'>{this.state.fields.cookie_banner}</div>
                <div>
                    <button 
                        className='app-button'
                        onClick={() => this.acceptCookies()}
                    >{this.state.fields.button_label}</button>
                    <a
                        className='styled-link' 
                        href={this.state.fields.policy_href}
                    >{this.state.fields.policy_label}</a>
                </div>
            </div>
        );
    }
}

export default CookiesBanner;