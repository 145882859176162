import React from 'react';

function RadioButtonField(props) {
    const { name, label, options } = props;
    if (!name) return null;
    const radioButton = () => options.map((option, index) => (
        <label 
            aria-label={name}
            htmlFor={name}
            key={props.key} 
            className='kmk-radiobutton'
        >
            {option.value}
            <input 
                name={name} 
                type='radio' 
                checked={index === 0 ? true : null}
                onChange={(value) => props.onChange({ target: { value: option.key } })}
            />
            <span></span>
        </label>
    ));
    return (
        <div key={name} className='kmk-radiobutton-container'>
            <label className='kmk-radiobutton-label'>{label}</label>
            {radioButton()}
        </div>
    );
}

export default RadioButtonField;