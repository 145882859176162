import InputField from "../ui-components/form-fields/input";
import TextArea from "../ui-components/form-fields/text-area";
import RadioButton from "../ui-components/form-fields/radiobutton";
import Conditions from "../ui-components/form-fields/conditions";
import Checkbox from "../ui-components/form-fields/checkbox";

const FIELDS_MAP =  {
    input: InputField,
    textarea: TextArea,
    radiobutton: RadioButton,
    conditions: Conditions,
    checkbox: Checkbox
};

export default FIELDS_MAP;
