import React from 'react';
import Slick from 'react-slick';
import './logo-carousel.css';
import { configuration } from '../../../configuration/configuration';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

class LogoCarousel extends React.Component {

    static defaultProps = {
        autoplay: true,
        infinite: true,
        showDots: false,
        carouselInterval: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        images: [],
        imagesFit: 'contain',
        h2: null,
        h2_style: null,
    }

    constructor(props) {
        super(props);
        this.state = {
            autoplay: props.autoplay,
            infinite: props.infinite,
            speed: props.carouselInterval,
            dots: props.showDots,
            slidesToShow: props.slidesToShow,
            slidesToScroll: props.slidesToScroll,
            centerMode: props.centerMode,
            lazyLoad: true,
        }
    }

    renderCarouselContent() {
        const { imageFit } = this.props;
        return this.props.images.map((image, index) => {
            return (
                <div key={index} className='carousel-element'>
                    <img 
                        style={{ objectFit: imageFit }} 
                        src={`${configuration.STATICS_URL}${image}`} 
                        alt={`logo-${index}`}
                    />
                </div>
            );
        });
    }

    render() {
        const { h2, h2_style } = this.props;
        return (
            <LazyLoadComponent>
                <div className='logo-carousel-container'>
                    <h2 style={h2_style}>{h2}</h2>
                    <Slick {...this.state}>
                        {this.renderCarouselContent()}
                    </Slick>
                </div>
            </LazyLoadComponent>
        )
    }
}

export default LogoCarousel;