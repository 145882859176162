import React from 'react';

function InputField(props) {
    const { name, label, error } = props;
    if (!name) return null;
    return (
        <div className='form-input' data-error={!!error} key={props.key}>
            <label htmlFor={name} aria-label={name}></label>
            <input
                id={name}
                onKeyDown={(e) => { if (e.keyCode === 13) props.onEnterPressed() }}
                onFocus={() => props.onFocus(name)}
                key={name}
                className='kmk-form-input'
                name={name} 
                placeholder={label}
                onChange={(value) => { props.onChange(value) } }
            />
            { error && error.map(e => (<span key={e} className='form-input-error'>{e}</span>)) }
        </div>
    );
}

export default InputField;
