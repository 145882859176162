import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

export class PureKMKCTATile extends React.PureComponent {

    render() {
        const { h2, paragraph, link } = this.props.data;
        return (
            <>
                <div className='cta-section'>{LayoutBuilder.buildH2(h2)}</div>
                <div className='cta-section'>
                    {LayoutBuilder.buildParagraph(paragraph)}
                    {LayoutBuilder.buildLink(link)}
                </div>
            </>
        );
    }
}

export const KMKCTATile = UIComponent(PureKMKCTATile, 'kmk-cta-tile', ['h2', 'paragraph'])
