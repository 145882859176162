import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import './another-floor-blocks.css';
import LayoutBuilder from "../../layout-builder/layout-builder";

class PureAFHeaderHoverBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hovered: false };
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    renderImageContainer() {
        if (!this.props.data.images) { return null; }
        return (<>{LayoutBuilder.getStyledImage(this.props.data.images.images)}</>);
    }

    handleMouseEnter() {
        this.setState({ hovered: true });
    }

    handleMouseLeave() {
        this.setState({ hovered: false });
    }

    render() {
        return (
            <div className='af-header-background'>
                {LayoutBuilder.getStyledImage(this.props.data.background.images)}
                <div className='af-background-layer-top' data-active={this.state.hovered}>{LayoutBuilder.getStyledImage(this.props.data.background.images, 1)}</div>
                <div className='text-container'>
                    {LayoutBuilder.buildH1(this.props.data.h2)}
                    {LayoutBuilder.buildParagraph(this.props.data.paragraph)}
                    {LayoutBuilder.buildButtons(this.props.data.buttons.buttons)}
                </div>
                <div
                    onMouseEnter={() => this.handleMouseEnter()}
                    onMouseLeave={() => this.handleMouseLeave()}
                    className='mouse-detector'
                ></div>
                {this.props.data.logo && <div className='overlay-logo' style={LayoutBuilder.getStyleFromImages(this.props.data.logo)}>{LayoutBuilder.getStyledImages(this.props.data.logo.images)}</div>}
            </div>
        );
    }
}

export const AFHeaderHover = UIComponent(PureAFHeaderHoverBlock, COMPONENT_MAP.AFHEADERHOVER, ['background', 'buttons']);
