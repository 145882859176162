import React from 'react';
import { KMKMenu } from '../menu/kmk-menu';
import { animateScroll as scroll } from 'react-scroll';
import AppLogo from "../logo/svg-logo";
import TopBarInfo from "./top-bar-info";
import UseContrastProbe from "../../hoc/use-contrast-probe";
import './top-bar.css';

class PureTopBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onLogoClicked() {
        if (window.location.pathname === '/') {
            scroll.scrollToTop({ delay: 0, smooth: "linear", });
            return;
        }

        window.location.href = '/';
    }

    render() {
        return (
            <div className='top-bar ignored-from-capture'>
                <AppLogo
                    onClick={() => this.onLogoClicked()}
                    className='top-bar-logo'
                    contrast={this.props.contrast}
                />
                <TopBarInfo contrast={this.props.contrast}/>
                <KMKMenu contrast={this.props.contrast} />
            </div>
        )
    } 
}

const TopBar = UseContrastProbe(PureTopBar, 60);
export default TopBar;
