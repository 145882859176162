import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import './another-floor-blocks.css';
import LayoutBuilder from "../../layout-builder/layout-builder";
import SliderIndicator from "./slider-indicator";
import Swipe from 'react-easy-swipe';

class PureAFCardBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: { cards: [] }, currentCard: 0 };
        this.parseCardData(props.data.cards.data);
        this.setIndex = this.setIndex.bind(this);
    }

    componentDidMount() {
        this.setCarouselTranslation();
        setTimeout(() => this.setOffsetTop(), 100);
    }

    decrementIndex() {
        const prevIndex = (this.state.currentCard - 1 + this.state.data.cards.length) % this.state.data.cards.length;
        clearInterval();
        this.setState({ currentCard: prevIndex, animate: true });
    }

    incrementIndex() {
        const nextIndex = (this.state.currentCard + 1) % this.state.data.cards.length;
        clearInterval();
        this.setState({ currentCard: nextIndex, animate: true });
    }

    setCarouselTranslation() {
        if (!this.state.data) { return; }
        const interval = setInterval(() => {
            const nextIndex = (this.state.currentCard + 1) % this.state.data.cards.length;
            this.setIndex(nextIndex);
        }, 10000);

        this.setState({ interval });
    }

    parseCardData(data) {
        try {
            const parsedCustom = JSON.parse(data);
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state = {
                data: parsedCustom,
                currentCard: 0,
                uuid: new Date().getTime(),
                animate: true
            };
            // eslint-disable-next-line react/no-direct-mutation-state
        } catch (e) { this.state = { uuid: new Date().getTime() }; }
    }

    setIndex(index) {
        clearInterval(this.state.interval);
        this.setCarouselTranslation();
        this.setState({ currentCard: index, animate: true });
        setTimeout(() => this.setState({ animate: false }), 600);
    }

    setIndexWithTimeout(index) {
        const timeout = setTimeout(() => this.setIndex(index), 150);
        this.setState({ timeout });
    }

    clearIndexTimeout() {
        clearInterval(this.state.timeout);
    }

    setOffsetTop() {
        try {
            const el = document.getElementById(this.state.uuid);
            this.setState({ offsetTop: el.offsetTop - 5 });
        } catch (e) {
            this.setState({ offsetTop: 0 });
        }
    }

    render() {
        if (!this.state.data || !this.state.data.cards) { return null; }
        return (
            <Swipe
                className='af-card-swiper'
                onSwipeLeft={() => this.incrementIndex()}
                onSwipeRight={() => this.decrementIndex()}
                onSwipeMove={(pos) => { if (Math.abs(pos.y) < 5) { return true; } }}
            >
                <div className='card-list'>
                    {LayoutBuilder.buildParagraph(this.props.data.paragraph)}
                    <ul id={this.state.uuid}>
                        {this.state.data.cards.map((card, i) => {

                            return (
                                <li
                                    key={i}
                                    data-active={i===this.state.currentCard}
                                    onClick={() => this.setIndex(i)}
                                    onMouseEnter={() => this.setIndexWithTimeout(i)}
                                    onMouseLeave={() => this.clearIndexTimeout()}
                                >
                                    <h2><span>{card.name}</span></h2>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div
                    className='card-content'
                    data-animate={this.state.animate}
                    style={{ marginTop: this.state.offsetTop }}
                    dangerouslySetInnerHTML={{ __html: this.state.data.cards[this.state.currentCard].html }}
                ></div>
                <SliderIndicator
                    index={this.state.currentCard}
                    onClick={(index) => this.setIndex(index)}
                />
            </Swipe>
        );
    }
}

export const AFCard = UIComponent(PureAFCardBlock, COMPONENT_MAP.AFCARD, []);
