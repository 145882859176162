import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import Swipe from 'react-easy-swipe';
import LayoutBuilder from "../../layout-builder/layout-builder";
import SliderIndicator from "./slider-indicator";

class PureAFBooklet extends React.Component {

    constructor(props) {
        super(props);
        this.imageSectionRef = React.createRef();
        this.textSectionRef = React.createRef();
        this.state = {
            index: 0,
            cards: [],
            currentCard: 0,
            animate: false,
            minHeight: 400,
        }
    }

    componentDidMount() {
        this.parseComponentData(this.props.data.cards);
        this.startCarousel();
    }

    decrementIndex() {
        const prevIndex = (this.state.index - 1 + this.state.cards.length) % this.state.cards.length;
        this.clearIndexTimeout();
        this.setState({ index: prevIndex, animate: true }, () => {
            this.setCarouselTranslation();
        });
    }

    incrementIndex() {
        const nextIndex = (this.state.index + 1) % this.state.cards.length;
        this.clearIndexTimeout();
        this.setState({ index: nextIndex, animate: true }, () => {
            this.setCarouselTranslation();
        });
    }

    startCarousel() {
        if (!this.state) { return; }
        const interval = setInterval(() => {
            const nextIndex = (this.state.index + 1) % this.state.cards.length;
            this.setIndex(nextIndex);
        }, 10000);

        this.setState({ interval }, () => this.setCarouselTranslation());
    }

    setIndex(index) {
        if (index > this.state.cards.length || index > this.state.cards.length - 1) return;
        this.setState({ index }, () => {
            this.setCarouselTranslation();
        });
    }

    setIndexWithTimeout(index) {
        const timeout = setTimeout(() => this.setIndex(index), 150);
        this.setState({ timeout });
    }

    clearIndexTimeout() {
        clearInterval(this.state.timeout);
    }

    parseComponentData(input) {
        try {
            const {data} = input;
            const output = JSON.parse(data);
            this.setState({ cards: output } , () => {
                this.getMaxCardHeight();
            });
        } catch (e) {}
    }

    setCarouselTranslation() {
        try {
            const imageStrip = this.imageSectionRef.current;
            imageStrip.childNodes.forEach((e, i) => {
                i === this.state.index ? e.style.opacity = '1' : e.style.opacity = '0';
            });
        } catch (e) {}
    }

    getMaxCardHeight() {
        const childNodes = this.textSectionRef.current.childNodes;
        let maxHeight = 0;
        childNodes.forEach(node => {
            maxHeight = node.getBoundingClientRect().height > maxHeight ? node.getBoundingClientRect().height : maxHeight;
        });

        this.setState({ minHeight: (maxHeight + 20) });
    }

    render() {
        const textColor = '#FFF';
        const underlineStyle = { "--underline-color": textColor };
        const card = this.state.cards[this.state.index];
        if (!card) { return null; }
        return (
            <Swipe
                className='af-booklet-swiper'
                onSwipeLeft={() => this.incrementIndex()}
                onSwipeRight={() => this.decrementIndex()}
                onSwipeMove={(pos) => { if (Math.abs(pos.y) < 5) { return true; } }}
            >
                <div
                    className='text-container'
                    style={{ minHeight: this.state.minHeight }}
                    ref={this.textSectionRef}
                >
                    {this.state.cards.map((card, i) => (
                        <div key={i} className='text-card' style={{ opacity: i===this.state.index ? '1' : '0' }}>
                            {card.label.content && <label style={underlineStyle}>{card.label.content}</label>}
                            {card.header.content && <h2 style={underlineStyle}><span data-active={true} className='underline'>{card.header.content}</span></h2>}
                            {LayoutBuilder.buildParagraph(card.paragraph)}
                        </div>))
                    }
                    <SliderIndicator count={this.state.cards.length} index={this.state.index} onClick={(index) => this.setIndex(index)}/>
                </div>
                <div className='image-container' ref={this.imageSectionRef}>{LayoutBuilder.getStyledImages(this.props.data.images)}</div>
            </Swipe>
        );
    }
}

const AFBooklet = UIComponent(PureAFBooklet, COMPONENT_MAP.AFBOOKLET, ['cards']);
export default AFBooklet;
