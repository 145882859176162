import React from 'react';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';
import LayoutBuilder from '../layout-builder/layout-builder';

class PureKMKLinkEmbededTile extends React.PureComponent {

    render() {
        const { h1, h2 } = this.props.data;
        return (
            <>
                {LayoutBuilder.buildH1(h1)}
                {LayoutBuilder.buildH2(h2)}
                <div dangerouslySetInnerHTML={{ __html: this.props.data.htmlContent.html }}></div>
            </>
        );
    }
}

export const KMKLinkEmbededTile = UIComponent(PureKMKLinkEmbededTile, 'kmk-link-embeded-tile', ['htmlContent']);
