import React from 'react';
import axios from 'axios';
import './ui-components.css';
import { configuration } from '../configuration/configuration';
import { SVGLogo } from './logo/kmk-logo';
import SendButton from '../assets/icons/newsletter_button.svg';
import Check from '../assets/icons/checkbox_white.svg';
import Checkbox from './form-fields/checkbox';
import omit from 'lodash-es/omit';
import Youtube from '../assets/icons/youtube_ico.svg';
import Facebook from '../assets/icons/fb_ico.svg';
import Instagram from '../assets/icons/insta_ico.svg';
import { PolicyPopup } from './policy-popup/policy-popup';

class PolicyLink extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { opened: false };
    }
    
    render() {
        return (
            <>
                {this.state.opened && <PolicyPopup onClosed={() => this.setState({ opened: false })} />}
                <label>{this.props.label}</label>
                <label className='kmk-footer-tile-policy-link' onClick={() => this.setState({ opened: true })}>{this.props.link}</label>
            </>
        )
    }
}

export class KMKFooterLP extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            newsletterConditions: false,
            email: '',
            formSchema: 0,
            postMessage: null,
        }
        this.fetchComponentDetails = this.fetchComponentDetails.bind(this);
    }

    componentWillMount() {
        this.fetchComponentDetails('KMKFooterTile');
    }

    fetchComponentDetails(component_name) {
        return axios.get(configuration.BLOCKS_URL + '?name=' + component_name)
        .then( res => {
            this.setState({ data: res.data.fields, formSchema: res.data.fields.form_schema_id });
        });
    }

    submitNewsletter() {
        if (this.state.postMessage) return;
        const payload = {
            fields: [
                {
                    name: 'email',
                    value: this.state.email,
                },
                {
                    name: 'conditions',
                    value: this.state.newsletterConditions
                }
            ],
            _id: this.state.formSchema
        };

        axios.post(configuration.API_URL + '/cms/form', payload)
            .then(response => {
                this.setState({ postMessage: response.data.postFunction.postMessage });
            })
            .catch(error => {
                if (!error || !error.response) return;
                const errors = error.response.data.errorMessage;
                this.setState({ error: errors });
            });
    }

    render() {
        return (
            <footer className="kmk-footer-tile" ref={ this.blockRef }>
                <div className="kmk-footer-tile-container">
                    <section className="kmk-footer-tile-section">
                        <div><SVGLogo logoStyle={{ width: '235px', height: '70px' }}/></div>
                    </section>
                    <section className="kmk-footer-tile-section">
                        <div><a href={this.state.data.faq_href}>{this.state.data.faq_label}</a></div>
                        <div><a href={this.state.data.privacy_policy_href}>{this.state.data.privacy_policy_label}</a></div>
                        <div><a href={this.state.data.ecommerce_href}>{this.state.data.ecommerce_label}</a></div>
                    </section>
                    <section className="kmk-footer-tile-section kmk-footer-tile-newsletter">
                        <div>
                            <label className='newsletter-label'>
                                {this.state.postMessage || this.state.data.newsletter_label}
                            </label>
                            <div className='newsletter-input-wrapper'>
                                <label 
                                    htmlFor="newsletter-email"
                                    aria-label="newsletter-email"
                                ></label>
                                <input
                                    onFocus={() => this.setState({ error: omit(this.state.error, ['email']) })}
                                    onChange={(e) => this.setState({ email: e.target.value})}
                                    className='newsletter-input' 
                                    name="newsletter-email"
                                    id="newsletter-email"
                                    placeholder={this.state.data.newsletter_input_placeholder} 
                                />
                                <button className={this.state.postMessage ? 'submitted' : ''} onClick={() => this.submitNewsletter()}>
                                    <img src={this.state.postMessage ? Check : SendButton} alt="Send"/>
                                </button>
                            </div>
                            {this.state.error && this.state.error.email && 
                                <span style={{ marginTop: 0 }} className='kmk-form-validation-error newsletter-error'>
                                    {this.state.error.email.map((e, i) => i === 0 ? e : '')}
                                </span>}
                            <Checkbox 
                                name='newsletter-conditions'
                                label={<PolicyLink label={this.state.data.newsletter_form_conditions_label} link={this.state.data.newsletter_form_conditions_policy_label}/>}
                                style={{ fontSize: '14px'}}
                                error={this.state.error ? this.state.error.conditions : null}
                                onChange={(e) => this.setState({ newsletterConditions: e.target.value })}
                                onFocus={() => this.setState({ error: omit(this.state.error, ['conditions']) })}
                            />
                        </div>
                    </section>
                    {this.state.data.contact_label && this.state.contact_phone &&
                        <section className="kmk-footer-tile-section">
                            <div><a href={"''"}>{this.state.data.contact_label}</a></div>
                            <div><a href={`tel:${this.state.data.contact_phone}`}>{this.state.data.contact_phone}</a></div>
                            <div><a href={`mailto:${this.state.data.contact_email}`}>{this.state.data.contact_email}</a>
                            </div>
                            <div className='icons-container'>
                                <a href={this.state.data.contact_youtube}><img src={Youtube} alt="Youtube"/></a>
                                <a href={this.state.data.contact_facebook}><img src={Facebook} alt="Facebook"/></a>
                                <a href={this.state.data.contact_instagram}><img src={Instagram} alt="Instagram"/></a>
                            </div>
                        </section>
                    }
                </div>
                <div className='kmk-footer-tile-caption'>
                    <a href={this.state.data.implementation_href}>{this.state.data.implementation}</a>
                </div>
            </footer>
        );
    }
}
