import UIComponent from '../hoc/ui-component';
import CustomComponentFactory from './custom-components/custom-components-factory';

function PureKMKCustomTile(props) {
    const { name, data } = props.data.custom;
    try {
        const componentData = JSON.parse(data);
        return CustomComponentFactory(name, componentData);
    } catch (e) {
        return null;
    }
}

export const KMKCustomTile = UIComponent(PureKMKCustomTile, 'kmk-custom-tile', ['custom']);
