import React from 'react';
import useCMSBlock from "../../hoc/use-cms-block";
import Clear from '../../assets/icons/clear-button-light.svg';

class PureCookiesBanner extends React.PureComponent {

    constructor(props) {
        super(props);
        try {
            const userAgent = navigator.userAgent;
            const isCrawler = userAgent === 'ReactSnap';
            const accepted = isCrawler ? true : (localStorage.getItem('cookies-accepted') || false);
            this.state = {
                accepted,
                visible: true,
            }
        } catch(e) {
            this.state = {
                accepted: true,
                visible: false,
            }
        }
    }

    dismissBanner() {
        try {
            this.setState({ visible: false });
            setTimeout(() => this.setState({ accepted: true }), 450);
            localStorage.setItem('cookies-accepted', true);
        } catch(e) {};
    }

    render() {
        if (this.state.accepted) { return null; }
        if (!this.state.visible) { return null; }
        return (
            <div className='af-cookies-banner'>
                <div className='banner-section' style={{ marginBottom: '1.5rem', maxWidth: '70%' }}>
                    <label>{this.props.data.policy_label}</label>
                </div>
                <div className='banner-section'>
                    <div className='buttons-container'>
                        <button onClick={() => this.dismissBanner() }>{this.props.data.aggree_button_label}</button>
                        <button>{this.props.data.more_button_label}</button>
                    </div>
                </div>
                <img
                    className='close-banner'
                    src={Clear}
                    alt='Close banner'
                    onClick={() => this.setState({ visible: false })}
                />
            </div>
        );
    }
}

const CookiesBanner = useCMSBlock(PureCookiesBanner, 'af-cookies-banner');
export default CookiesBanner;
