import React from 'react';
import './ui-components.css';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';

class PureKMKTextTile extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { isVisible: false };
        this.blockRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll(null);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const rect = this.blockRef.current.getBoundingClientRect();
        // const isVisible = (rect.top >= 0 && rect.bottom <= window.innerHeight + 380);
        const isVisible = (rect.top >= 0 && rect.top <= window.innerHeight);
        if (isVisible) { this.setState({ isVisible: isVisible }); }
    }

    isVisible() {
        return this.state.isVisible ? ' show-block' : '';
    }

    renderComponentContent() {
        const { h1, h2, paragraph } = this.props.data;
        const hasMargin = !!(h2 && h2.content);
        return (
            <div className={hasMargin ? '' : 'no-margin'}>
                {LayoutBuilder.buildH1(h1)}
                {LayoutBuilder.buildH2(h2)}
                {LayoutBuilder.buildParagraph(paragraph)}
            </div>
        );
    }

    render() {
        const hasBackground = !!this.props.data.options.backgroundImage;
        const contentClass =`show-block-pre ${this.isVisible()} ${hasBackground ? 'with-minimal-height' : ''}`;
        return (
            <div className={contentClass} ref={this.blockRef}>{this.renderComponentContent()}</div>
        );
    }
}

export const KMKTextTile = UIComponent(PureKMKTextTile, 'kmk-text-tile', ['h1', 'h2', 'paragraph'], false);
