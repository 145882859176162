import React from 'react';
import './ui-components.css';
import UIComponent from '../hoc/ui-component';
import LayoutBuilder from '../layout-builder/layout-builder';
import KMKLazyImage from './lazy-loading/kmk-lazy-image';
import { configuration } from '../configuration/configuration';
import { MetaTags } from 'react-meta-tags';

class PureKMKTitleTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = { offset: 0 };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        let scrollTop = window.scrollY / 3;
        this.setState({ offset: scrollTop });
    }

    getTranslation() {
        return `translate3d(0, ${this.state.offset}px, 0)`;
    }

    renderMetaTags(src) {
        const originalSrc = src.replace(/_[sol].|\./, '_o.');
        const source = configuration.STATICS_URL + originalSrc;
        return (<meta property="og:image" content={source} />);
    }

    render() {
        const { h1 } = this.props.data;
        const images = this.props.data.images.images || this.props.data.images;
        
        if (!images) { return null; }

        const image = images[0];
        const lazyImageStyle = {
            transform: this.getTranslation(),
        };
        
        return (
            <div className='kmk-title-tile-container'>
                <MetaTags>{this.renderMetaTags(image.source)}</MetaTags>
                <KMKLazyImage 
                    images={images}
                    src={image.source} 
                    alt={image.alt} 
                    style={lazyImageStyle}
                />
                {LayoutBuilder.buildH1(h1)}
            </div>
        );
    }
}

export const KMKTitleTile = UIComponent(PureKMKTitleTile, 'kmk-title-tile', ['images']);
