import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureKMKPeopleTile extends React.PureComponent {

    render() {
        const { images, h2, paragraph } = this.props.data;
        return (
            <>
                {LayoutBuilder.getStyledImages(images)}
                {LayoutBuilder.buildH2(h2)}
                {LayoutBuilder.buildParagraph(paragraph)}
            </>
        )
    }
}

export const KMKPeopleTile = UIComponent(PureKMKPeopleTile, 'kmk-people-tile', ['images', 'h2', 'paragraph']);
