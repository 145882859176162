import React from 'react';
import TopBar from '../ui-components/top-bar/top-bar';
import withCMSPage from '../hoc/cms-page';
import { PagePopup } from '../ui-components/popup/page-popup';
import CookiesBanner from "../ui-components/another-floor-blocks/af-cookies-banner";

function PureHome(props) {
    return (
        <>
            <TopBar />
            <PagePopup />
            <CookiesBanner />
            <div className="masonry-layout">{ props.components }</div>
        </>
    );
}

const Home = withCMSPage(PureHome);

export default Home;
