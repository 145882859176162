import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import LayoutBuilder from "../../layout-builder/layout-builder";
import {getOriginalSrc} from "../../rwd/rwd";

class PureAFImageTransition extends React.Component {

    static defaultProps = {
        interval: 4000,
        height: '500px',
    };

    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this);
        this.containerRef = React.createRef();
        this.state = {
            index: 0,
            length: 0,
            images: [],
            containerHeight: 700,
        }
    }

    componentDidMount() {
        const {images} = this.props.data.images;
        this.setState({ images, length: images.length }, () => {
            this.startCarousel();
            this.setContainerHeight();
        });
    }

    setContainerHeight() {
        const img = new Image();
        const that = this;
        img.onload = function() {
            const ratio = this.width / this.height;
            that.setState({ containerHeight: window.innerWidth / ratio });
        }
        img.src = getOriginalSrc(this.state.images[0].source);
    }

    incrementIndex() {
        const nextIndex = (this.state.index + 1) % (this.state.length);
        this.setState({ index: nextIndex }, () => {
            this.containerRef.current.childNodes.forEach((node, i) => {
                node.style.opacity = (i === this.state.index) ? '1' : '0';
            })
        });
    }

    startCarousel() {
        this.interval = setInterval(() => {
            this.incrementIndex();
        }, Number(this.props.data.interval.label));
    }

    render() {
        return (
            <div
                ref={this.containerRef}
                style={{ minHeight: this.state.containerHeight }}
            >
                {LayoutBuilder.getStyledImages(this.state.images)}
                {LayoutBuilder.getStyledLogo(this.props.data)}
            </div>
        );
    }
}

const AFImageTransition = UIComponent(PureAFImageTransition, COMPONENT_MAP.AFIMAGETRANSITION, ['images']);
export default AFImageTransition;
