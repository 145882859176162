import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import moment from 'moment-timezone';

class PureAFTimer extends React.PureComponent {

    componentDidMount() {
        this.parseCustom(this.props.data.custom.data);
        this.initTimer();
        this.setContrast();
    }

    parseCustom(data) {
        try {
            const parsedData = JSON.parse(data);
            this.setState(parsedData);
        } catch (e) {
            this.setState({ timeZone: 'Europe/Warsaw' });
        }
    }
    setTime() {
        const { timeZone } = this.state;
        const time = moment.tz(timeZone).format('LTS');
        this.setState({ time });
    }
    setContrast() {
        const getBackgroundColor = (c) => {
            if (c === '') { return [255,255,255]; }
            return c.replace(/(rgb\()|(\))/g, '')
                .split(',')
                .map(v => parseInt(v));
        };
        this.setState({ contrast: this.getContrast(getBackgroundColor(this.props.data.options.backgroundColor)) });
    }
    getContrast(color) {
        const [r,g,b] = color;
        const contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
        return (contrast >= 128) ? '#FFF' : '#000';
    }
    initTimer() {
        setInterval(() => this.setTime(), 1000);
    }
    render() {
        if (!this.state) { return null; }
        if (!this.props.data.custom) { return null; }
        return (
            <>
                <div className='timer-section align-top'>
                    {this.state.gmt && <span className='gmt'>{this.state.gmt}</span>}
                    {this.state.label && <div className='label' dangerouslySetInnerHTML={{ __html: this.state.label }}></div>}
                </div>
                <div className='timer-section'>
                    {this.state.localization && <span className='localization'>{this.state.localization}</span>}
                    <div style={{ color: this.state.contrast }} className='timer-wrapper'>{this.state.time}</div>
                </div>
            </>
        );
    }
}

export const AFTimer = UIComponent(PureAFTimer, COMPONENT_MAP.AFTIMER, []);
