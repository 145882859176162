/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import axios from 'axios';
import { PageBuilder } from '../builder/page-builder';
import { configuration } from '../configuration/configuration';
import MetaTags from 'react-meta-tags';
import EventBus from '../event-bus/event-bus';

export default function withCMSPage(PageComponent) {
    return class extends React.PureComponent {

        constructor(props) {
            super(props);
            this.state = {
                query: this.buildQuery(),
                components: null,
                url: configuration.CANONICAL + '/' + this.props.location.pathname.replace(/\//gi, ''),
            }
            this.setComponents = this.setComponents.bind(this);
            this.setComponents(this.state.query);
        }

        buildQuery() {
            const page_endpoint = this.props.location.pathname.replace(/\//gi, '');
            const fetchUrl = configuration.API_URL + '/page?endpoint=';
            if (page_endpoint === '') { return fetchUrl + 'index'; }
            return fetchUrl + page_endpoint;
        }

        publishInitEvent(customEvent) {
            if (customEvent) {
                try {
                    const ce = JSON.parse(customEvent);
                    EventBus.dispatchEvent(ce.event, { ...ce, query: this.props.location });
                } catch(e) {}
            }
        }

        publishCookieEvent(cookies) {
            if (!cookies || cookies.length === 0) { return; }
            EventBus.dispatchEvent('set-cookie', { cookies });
        }

        fetchPageDetails(endpoint) {
            axios.get(endpoint, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then( response => {
                document.title = configuration.TITLE_PREFIX + response.data.title;
                const pb = new PageBuilder();

                this.publishInitEvent(response.data.customEvent);
                this.publishCookieEvent(response.data.cookies);
                this.setState({ 
                    components: pb.buildPage(response.data.sections),
                    metas: response.data.metas,
                    isBlog: response.data.blog ? true : false,
                    blog: response.data.blog,
                    title: response.data.title
                });
            })
            .catch( (e) => {
                // window.location.href='/not-found';
            });
        }

        setComponents(queryKey) {
            if (window.snapStore && window.snapStore[queryKey]) {
                const data = window.snapStore[queryKey];
                const components = new PageBuilder().buildPage(data.sections);
                this.state = { ...this.state, components, isBlog: !!data.isBlog || false, blog: data.blog, title: data.title };
            } else {
                this.fetchPageDetails(queryKey); 
            }
        }

        renderMetaTags() {
            const { metas } = this.state;
            return metas && metas.map((meta, index) => (<meta key={index} name={meta.name} content={meta.content} />));
        }

        renderOgMetas() {
            const { title, url } = this.state;
            return (
                <>
                    <meta property="og:title" content={title} />
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content={url} />
                </>
            );
        }

        render() {
            return (
                <>
                    <MetaTags>{this.renderMetaTags()}</MetaTags>
                    <MetaTags>{this.renderOgMetas()}</MetaTags>
                    <PageComponent {...this.props} {...this.state}/>
                </>
            )
        }
    }
}
