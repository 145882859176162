import React from 'react';
import axios from 'axios/index';
import {configuration} from "../configuration/configuration";

function useCMSBlock(WrapperComponent, blockname) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = { data: null };
        }
        fetchCMSBlock() {
            axios.get(configuration.BLOCKS_URL + '?name=' + blockname)
                .then(
                    res => { this.setState({ data: res.data.fields }); }
                )
                .catch(() => {});
        }
        componentDidMount() {
            this.fetchCMSBlock();
        }
        render() {
            if (!this.state.data) { return null; }
            return (
                <WrapperComponent {...this.props} data={ this.state.data }/>
            )
        }
    }
}

export default useCMSBlock;
