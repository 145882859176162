import React from 'react';

export const NotFound = () => {
    const containerStyle = {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
    };
    const h1Style = {
        minWidth: 'unset',
        fontSize: '10rem'
    }
    const pStyle = {
        lineHeight: '1.5',
        margin: '1rem 0',
        maxWidth: '500px',
        textAlign: 'center'
    }
    const buttonStyle = {
        fontSize: '1rem',
        fontWeight: 800,
        color: '#FFF',
        backgroundColor: '#F00C21',
        padding: '15px 30px',
        borderRadius: '30px'
    };

    return (
        <div style={containerStyle}>
            <h1 style={h1Style}>404</h1>
            <p style={pStyle}>Nie znaleziono strony której szukasz… Wróć do strony głównej by cieszyć się kwietnymi informacjami.</p>
            <a style={buttonStyle} href="/">Strona Główna</a>
        </div>
    )
}