import React, { PureComponent } from 'react';
import axios from 'axios';
import { configuration } from '../configuration/configuration';
import BlogTile from './blog-tile';
import './blog-tile.css';
import TopBar from '../ui-components/top-bar/top-bar';
import { KMKFooterTile } from '../ui-components/kmk-footer-tile';
import CookiesBanner from '../ui-components/custom-components/cookies-banner/cookies-banner';
import Loader from '../loader/loader';

class BlogPage extends PureComponent {

    state = {
        articles: [],
        pending: true
    }

    constructor(props) {
        super(props);
        this.fetchBlogArticles();
    }

    fetchBlogArticles() {
        axios.get(configuration.API_URL + '/public/blog/articles')
            .then(response => {
                const articles = response.data;
                this.setState({ articles, pending: false });
            })
            .catch(err => {
                console.log("Fetching error: ", err);
            });
    }

    renderArticleList() {
        const { articles } = this.state;

        return articles.map((props, index) => {
            return (index === 0) ? <BlogTile key={index} {...props} distinctive /> : <BlogTile key={index} {...props} />;
        });
    }

    render() {
        return (
            <>
                <TopBar title="Blog"/>
                <CookiesBanner />
                {this.state.pending && <Loader />}
                <div className='masonry-layout blog-page'>
                    {this.renderArticleList()}
                </div>
                <KMKFooterTile />
            </>
        );
    }

}

export default BlogPage;