import React from 'react';
import {connect} from "react-redux";

export default function UseContrastProbe(WrapperComponent, offsetY = 0) {
    const mapStateToProps = (state) => ({ ...state });
    class StatelessLogo extends React.Component {
        constructor(props) {
            super(props);
            this.state = { contrast: '#000' };
            this.handleScroll = this.handleScroll.bind(this);
        }

        componentDidMount() {
            if (!this.props.disabled) {
                window.addEventListener('scroll', this.handleScroll, { passive: true });
                this.init();
            }
        }
        componentWillUnmount() {
            if (!this.props.disabled) {
                window.removeEventListener('scroll', this.handleScroll);
            }
        }
        handleScroll() {
            this.setActiveSection();
        }
        init() {
            this.interval = setInterval(() => {
                if (this.props.sections.length === 0) {
                    this.setActiveSection();
                } else {
                    this.setActiveSection();
                    this.clearInterval();
                }
            }, 100);
        }
        clearInterval() {
            clearInterval(this.interval);
        }
        setActiveSection() {
            const {sections} = this.props;
            const scrollY = window.scrollY + offsetY;
            let activeSection = 0;

            if (!sections) return null;
            for (let i=0; i<sections.length; i++) {
                if (scrollY > sections[i].height) {
                    activeSection = sections[i];
                    continue;
                }
                break;
            }

            try {
                this.setState({ contrast: this.getContrast(activeSection.backgroundColor)});
            } catch (e) {}
        }
        getContrast(color) {
            const [r,g,b] = color;
            const contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
            return (contrast >= 128) ? '#000' : '#FFF';
        }
        render() {
            return (
                <WrapperComponent {...this.props} contrast={ this.state.contrast }/>
            )
        }
    }

    return connect(mapStateToProps)(StatelessLogo);
}
