import React from 'react';
import Slider from 'react-slick';
import { configuration } from '../configuration/configuration';
import './ui-components.css';
 
export class KMKCarouselTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {
                dots: true,
                arrows: false,
                infinite: true,
                autoplay: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
        this.setCarousel = this.setCarousel.bind(this);
        this.getCarouselContent = this.getCarouselContent.bind(this);
        this.setCarousel(this.props.data.carousel);
    }

    setCarousel(settings) {
        const stateSettings = this.state.settings;
        Object.assign(stateSettings, this.props.data.carousel);
        if (window.innerWidth <= 768) stateSettings.slidesToShow = 1;
        this.setState({ settings: stateSettings });
    }

    getCarouselContent(sections) {
       if (!sections) { return null; }
       const output_blocks = [];

       for (let i = 0; i < sections.length; i++) {
        switch (sections[i].type) {
            case 'html': {
                const block = <div className='kmk-carousel-html' key={i} dangerouslySetInnerHTML={ { __html: sections[i].htmlContent } }></div>
                output_blocks.push(block);
                break;
            }
            case 'image': {
                const imageStyle={ 
                    backgroundImage: 'url(' + configuration.STATICS_URL + sections[i].image.source + ')'
                };
                const block = (
                <div key={i}>
                    <div className='kmk-carousel-image' style={ imageStyle }></div>
                </div>);
                output_blocks.push(block);
                break;
            }
            case 'image-link': {
                const imageStyle={ 
                    backgroundImage: 'url(' + configuration.STATICS_URL + sections[i].image.source + ')'
                };
                const block = (
                    <div className='kmk-carousel-image-link' key={i}>
                        <div className='kmk-carousel-image-link-background' style={ imageStyle }></div>
                        <h1>{ sections[i].h1 }</h1>
                        <a href={ sections[i].link.href }>{ sections[i].link.label }</a>
                    </div>
                )
                output_blocks.push(block);
                break;
            }
            default: break;
        }
       }
       
       return output_blocks;
    }

    render() {
        const elements = this.getCarouselContent(this.props.data.sections);
        const { id } = this.props.data;
        return (
            <div id={id} className='kmk-carousel-tile'>
                <Slider {...this.state.settings}>
                    { elements }
                </Slider>
            </div>
        )
    }
}