import React, { Component } from 'react';
import store from './store/store';
import Main from './Main';
import { Provider } from 'react-redux';
import EventBus from './event-bus/event-bus';
import { animateScroll as scroll } from 'react-scroll';

class App extends Component {

  constructor() {
    super();
    EventBus.initialize();
    this.scrollOnInit = this.scrollOnInit.bind(this);
    this.delayScrollCheck = this.delayScrollCheck.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.delayScrollCheck);
  }

  delayScrollCheck() {
    setTimeout(() => this.scrollOnInit(), 200);
  }

  scrollOnInit() {
    const anchor = window.location.hash;
    if (!anchor || anchor.length === 0) return;

    const domElement = document.getElementById(anchor.replace(/#/gi, ''));
    if (!domElement) return null;
    const bodyRect = document.body.getBoundingClientRect();
    const topOffset = domElement.getBoundingClientRect().top - bodyRect.top;
    scroll.scrollTo(topOffset);
  }

  render() {
    return (
      <Main/>
    );
  }
}

export default () => (<Provider store={store}><App /></Provider>);