import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import './another-floor-blocks.css';

class PureAFHtmlBlock extends React.PureComponent {
    render() {
        if (!this.props.data.htmlContent) { return null; }
        if (!this.props.data.htmlContent.html) { return null; }
        return (
            <div dangerouslySetInnerHTML={{ __html: this.props.data.htmlContent.html }}></div>
        );
    }
}

export const AFHTML = UIComponent(PureAFHtmlBlock, COMPONENT_MAP.AFHTML, []);
