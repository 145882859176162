import React, { PureComponent } from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureKMKSummaryTile extends PureComponent {

    hasAdditionalMargin() {
        const { h2, paragraph } = this.props.data;
        if (h2.content.split(/\s/).length >= 4 && paragraph.content) return true;
        return false;
    }

    renderTextContainerSection() {
        const { h2, paragraph, link } = this.props.data;
        const buttonSectionIncluded = true;

        return (
            <>
                {LayoutBuilder.buildH2(h2)}
                {LayoutBuilder.buildParagraph(paragraph)}
                {LayoutBuilder.buildLink(link)}
                {buttonSectionIncluded && this.renderButtonSection()}
            </>
        );
    }

    renderImageContainerSection() {
        const { images } = this.props.data;
        return (
            <>
                {LayoutBuilder.getStyledImages(images)}
            </>
        );
    }

    renderButtonSection(insideTextContainer, hasImages) {
        const { buttons, buttonsContainer } = this.props.data;
        let buttonsView = buttons || buttonsContainer.buttons;
        const componentButtons = buttonsView
            .map(componentButton => (<div key={Math.random()} style={{ marginTop: "20px 10px 0 0" }}>{LayoutBuilder.buildButton(componentButton)}<br /></div>));
        // const containerClass = `kmk-summary-tile-buttons-container button-align-${button ? button.align : ''} ${hasImages ? 'has-images' : ''}`;
        const containerClass = `kmk-summary-tile-buttons-container ${hasImages ? 'has-images' : ''}`;
        return insideTextContainer ? 
            componentButtons : 
            (<div className={containerClass}>{componentButtons}</div>);
    }

    renderAlignedSections() {
        const { align } = this.props.data['image-align'] || 'left';
        let sections = [
            (
                <div 
                    key='text-section' 
                    className={`kmk-summary-tile-section kmk-summary-tile-text-section ${this.hasAdditionalMargin() ? 'additional-margin' : ''}`}
                >
                    {this.renderTextContainerSection()}
                </div>
            ),
            (
                <div key='image-section' className='kmk-summary-tile-section kmk-summary-tile-image-section'>
                    {this.renderImageContainerSection()}
                </div>
            )
        ];

        return (!align || align === 'right') ? sections : sections.reverse();
    }

    render() {
        return this.renderAlignedSections();
    }
}

export const KMKSummaryTile = UIComponent(PureKMKSummaryTile, 'kmk-summary-tile', ['h2', 'paragraph'], false);
