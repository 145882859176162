import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import LayoutBuilder from "../../layout-builder/layout-builder";

class PureAFVideoBanner extends React.PureComponent {

    render() {
        const backgroundColor = this.props.data.overlay ? this.props.data.overlay.backgroundColor : null;
        return (
            <div className='pure-af-video-banner-container'>
                <div
                    className='video-overlay'
                    style={{ backgroundColor }}
                ></div>
                <div className='content-container'>
                    {LayoutBuilder.buildH2(this.props.data.h2)}
                    {LayoutBuilder.buildParagraph(this.props.data.paragraph)}
                    {this.props.data.buttons && LayoutBuilder.buildButtons(this.props.data.buttons.buttons)}
                </div>
                <video
                    playsInline="playsinline"
                    autoPlay="autoplay"
                    muted="muted"
                    loop="loop"
                >
                    <source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4"/>
                </video>
            </div>
        );
    }

}

export const AFVideoBanner = UIComponent(PureAFVideoBanner, COMPONENT_MAP.AFVIDEOBANNER, []);
