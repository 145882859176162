import React from 'react';

class SliderIndicator extends React.Component {

    static defaultProps = {
        count:  6,
        index: 1,
        onClick: () => {},
    }

    render() {
        return (
            <div className='slider-indicator'>
                {Array(this.props.count).fill(0).map((el, index) => (
                    <div
                        key={index}
                        className='dot'
                        data-active={this.props.index===index}
                        onClick={() => this.props.onClick(index)}
                    >
                        <i></i>
                    </div>
                ))}
            </div>
        )
    }
}

export default SliderIndicator;
