import React from 'react';
import { configuration } from '../configuration/configuration';
import EventBus from '../event-bus/event-bus';
import { getSrcForRwd, getSrcForThumbnail } from '../rwd/rwd';
import ProgressiveImage from 'react-progressive-image';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '@material/react-ripple/dist/ripple.css';

const LayoutBuilder = {
    getLayoutProperties: (options) => {
        if (!options) return '';
        let ouputClass = '';
        if (options.hasOwnProperty('colwidth')) {
            ouputClass += ' col-' + options.colwidth;
        }
        if (options.hasOwnProperty('colstart')) {
            ouputClass += ' offset-' + options.colstart;
        }
        return ouputClass;
    },
    getBackgroundImageStyle: (options) => {
        if (!options) { return null; }
        if (!options.images) { return null; }
        if (!(options.images instanceof Array)) { return null; }
        const [image] = options.images;
        const style = {};
        if (image.source) { Object.assign(style, { backgroundImage: `url(${configuration.STATICS_URL}${image.source})` }); }
        if (image.fit) { Object.assign(style, { backgroundSize: image.fit }); }
        if (image.padding) { Object.assign(style, { padding: image.padding }); }

        return style;
    },
    getComponentStyle: (options) => {
        if (!options) return null;
        const style = {};
        Object.assign(style, options);
        if (options.justifycenter) { Object.assign(style, { justifyContent: 'center' }); }
        if (options.aligncenter) { Object.assign(style, { alignItems: 'center' }); }
        if (options.alignstart) { Object.assign(style, { alignItems: 'flex-start' }); }
        if (options.alignend) { Object.assign(style, { alignItems: 'flex-end' }); }
        if (options.maxheight) { Object.assign(style, { maxHeight: options.maxheight}); }
        if (options.backgroundColor) { Object.assign(style, { backgroundColor: options.backgroundColor } ); }
        if (options.paddingRight) { Object.assign(style, { paddingRight: options.paddingRight }); }
        if (options.paddingLeft) { Object.assign(style, { paddingLeft: options.paddingLeft }); }
        if (options.noMargin) { Object.assign(style, { margin: '0', padding: '0' }); }
        if (options.backgroundImage) { Object.assign(style, { backgroundImage: `url(${configuration.STATICS_URL}${options.backgroundImage})`}); }
        if (options.containerPadding) { Object.assign(style, { padding: options.containerPadding })}
        return style;
    },
    getH1Style: (h1_options) => {
        if (!h1_options) return;
        return {
            fontWeight: h1_options.weight, 
            color: h1_options.color,
            padding: h1_options.padding,
            textAlign: h1_options.align
        }
    },
    getH2Style: (h2_options) => {
        if (!h2_options) return;
        return {
            fontWeight: h2_options.weight,
            color: h2_options.color,
            padding: h2_options.padding,
            textAlign: h2_options.align,
        }
    },
    getParagraphStyle: (p_options) => {
        if (!p_options) return;
        return {
            fontWeight: p_options.weight,
            padding: p_options.padding,
            textAlign: p_options.align,
            color: p_options.color
        }
    },
    getLinkStyle: (linkOptions) => {
        if (!linkOptions) return null;
        return {
            textAlign: linkOptions.align,
            color: linkOptions.color,
        }
    },
    getButtonStyle: (buttonOptions) => {
        if (!buttonOptions) return null;
        const { color } = buttonOptions;
        const buttonTextColor = !color || 
            color.toLowerCase() === '#fff' || 
            color.toLowerCase().replace(/\s/, '') === 'rgb(255,255,255)'
            ? '#000' : '#fff';
        return {
            color: buttonTextColor,
            backgroundColor: buttonOptions.color,
        }
    },
    buildH1: (h1) => {
        if (!h1 || !h1.content || h1.content.length === 0) return null;
        const h1_style = LayoutBuilder.getH1Style(h1);
        return <h1 key={ 'h1key' } style={ h1_style } dangerouslySetInnerHTML={ { __html: h1.content } }></h1>;
    },
    buildH2: (h2) => {
        if (!h2 || !h2.content || h2.content.length === 0) return null;
        const h2_style = LayoutBuilder.getH2Style(h2);
        const no_padding = h2.align === 'center' ? 'no-padding' : '';
        return <h2 key={ 'h2key'} className={no_padding} style={ h2_style} dangerouslySetInnerHTML={ { __html: h2.content } }></h2>;
    },
    buildParagraph: (p, offset) => {
        if (!p || !p.content || p.content.length === 0) return null;
        const p_style = LayoutBuilder.getParagraphStyle(p);
        return <p key={ 'pkey' } style={ p_style } dangerouslySetInnerHTML={ { __html: p.content } }></p>;
    },
    buildLink: (link) => {
        if (!link || !link.label) return null;
        return <a style={LayoutBuilder.getLinkStyle(link)} href={link.href}>{link.label}</a>
    },
    buildButtons: (buttons) => {
        if (!buttons) { return null; }
        if (!(buttons instanceof Array)) { return null; }
        if (buttons.length === 0) { return null; }

        return buttons.map((button, i) => {
            let styles = {};
            try { styles = JSON.parse(`{${button.style}`); } catch (e) {}
            return (
                <button
                    key={i}
                    style={{ color: button.textColor, backgroundColor: button.color, ...styles }}
                    onClick={() => {
                        EventBus.dispatchEvent(button.eventName, button.eventData);
                    }}
                >
                    {button.label}
                </button>
            )
        });
    },
    buildList: (list) => {
        let innerHtml = list.list;
        if (!innerHtml) { innerHtml = list; }
        return <div key={'list'} dangerouslySetInnerHTML={{ __html: innerHtml }}></div>;
    },
    buildLabel: (content) => {
        if (!content) { return null; }
        const {label} = content;
        return label && <label style={LayoutBuilder.getH1Style(content)} dangerouslySetInnerHTML={{ __html: label }}></label>
    },
    getStyledImage: (images, index=0) => {
        if (!images) return null;
        if (!(images instanceof Array)) { images = images.images; }
        if (images.length === 0) { return null; }
        if (!images[index]) { return null; }
        const image = images[index];
        const imageStyle = {
            width: image.width ? image.width : '100%',
            maxHeight: image.height,
            padding: image.padding,
            objectFit: image.fit
        };

        const output = (
            <ProgressiveImage
                placeholder={getSrcForThumbnail(image.source)}
                src={getSrcForRwd(image.source)}
            >
                {(src, loading) => (
                    <img
                        data-loading={loading}
                        src={src}
                        alt={image.alt}
                        style={{ ...imageStyle, minHeight: loading ? image.height : null, filter: loading ? 'blur(5px)' : null }}
                    />
                )}
            </ProgressiveImage>
        );

        return output;
    },
    getResponsiveAwareImage: (images) => {
      if (!images) { return null; }
      if (images.length===1) { return LayoutBuilder.getStyledImage(images, 0); }
      const index = window.innerWidth > 768 ? 0 : 1;
      return LayoutBuilder.getStyledImage(images, index);
    },
    getStyledImages: (images, props) => {
        if (!images) return null;
        if (!(images instanceof Array)) { images = images.images; }
        const output_images = [];
        for (let i = 0; i < images.length; i++) {
            const imageStyle = { 
                width: images[i].width ? images[i].width : '100%', 
                maxHeight: images[i].height, 
                padding: images[i].padding,
                objectFit: images[i].fit
            };

            const image = (
                <ProgressiveImage
                    key={i}
                    placeholder={getSrcForThumbnail(images[i].source)}
                    src={getSrcForRwd(images[i].source)}
                    {...props}
                >
                    {(src, loading) => (
                        <img
                            data-loading={loading}
                            src={src}
                            alt={images[i].alt}
                            style={{ ...imageStyle, minHeight: loading ? images[i].height : null, filter: loading ? 'blur(5px)' : null }}
                        />
                    )}
                </ProgressiveImage>
            );

            output_images.push(image);
        }

        return output_images;
    },
    getImageAlign: (image) => {
        if (!image || !image.hasOwnProperty('align')) return '';
        switch (image.align) {
            case 'top': return '';
            case 'left': return ' image-align-left';
            case 'right': return ' image-align-right';
            case 'bottom': return ' image-align-bottom';
            default: return '';
        }
    },
    getCustomClass: (clazz) => {
        if (!clazz || !clazz.name) return '';
        return ` ${clazz.name}`;
    },
    getStyleFromImages: (object) => {
        const { images } = object;
        if (!images || !images.length) { return null; }
        return {
            width: images[0].width,
            height: images[0].height,
        }
    },
    getStyledLogo: (data) => {
        if (!data) { return null; }
        if (!data.hasOwnProperty('logo') || !data.logo.hasOwnProperty('images') || !data.logo.images.length) { return null; }
        return (<div className='overlay-logo'>{LayoutBuilder.getStyledImages(data.logo)}</div>);
    }
}

export default LayoutBuilder;
