import React from 'react';
import LayoutBuilder from "../../layout-builder/layout-builder";
import UIComponent from "../../hoc/ui-component";

class PureAFCTABackground extends React.PureComponent {

    render() {
        return (
            <>
                <div className='text-container'>
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.htmlContent.html}}></div>
                    {this.props.data.buttons && <div className='buttons-container'>{LayoutBuilder.buildButtons(this.props.data.buttons.buttons)}</div>}
                </div>
                <div className='background-image'>
                    {LayoutBuilder.getResponsiveAwareImage(this.props.data.background.images)}
                </div>
            </>
        )
    }
}

export const AFCTABackground = UIComponent(PureAFCTABackground, 'af-cta-background', ['background']);
