import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureKMKImageTextTile extends React.PureComponent {
    
    render() {
        const { h1, h2, paragraph, images } = this.props.data;
        return (
            <>
                <div className='kmk-image-text-tile-images'>{LayoutBuilder.getStyledImages(images)}</div>
                <div className='kmk-image-text-tile-content'>
                    {LayoutBuilder.buildH1(h1)}
                    {LayoutBuilder.buildH2(h2)}
                    {LayoutBuilder.buildParagraph(paragraph)}
                </div>
            </>
        )
    }
}

export const KMKImageTextTile = UIComponent(PureKMKImageTextTile, 'kmk-image-text-tile', ['h1', 'h2', 'paragraph']);
