import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import { default as MarkerGoogleMap } from 'google-map-react';
import { configuration } from '../configuration/configuration';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import MapStyle from "../map-style/map-style";

const { compose, withProps } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    KmlLayer,
  } = require("react-google-maps");

function KMKMapMarker(props) {
    const { source, alt, color } = props;
    const markerStyle = {
        color: color,
        width: '35px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'translate(-50%, -80%)'
    };
    const imgStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    }

    return (
        <div style={markerStyle}>
            <img 
                style={imgStyle} 
                src={ configuration.STATICS_URL + source } 
                alt={alt}
            />
        </div>
    )
}

class PureKMKMapTile extends React.PureComponent {

    static defaultProps = {
        center: {lat: 50.256079, lng: 19.011795},
        zoom: 5
    };

    getDefaultCenter() {
        const { markers } = this.props.data.map;
        if (!markers || markers.length === 0) return this.props.center;
        
        const headMarker = markers[0];
        const { lat, lng } = headMarker;
        if (!lat || !lng) return this.props.center;

        return { lat: Number(lat), lng: Number(lng) };
    }

    renderMarkers() {
        const { markers } = this.props.data.map;
        return markers.map(marker => {
            if (!marker) return null;
            return (
                <KMKMapMarker
                    key={marker.lng}
                    lat={Number(marker.lat)}
                    lng={Number(marker.lng)}
                    {...marker}
                />
            );
        });
    }

    renderKMLLayer() {

    }

    render() {
        if (!this.props.data.map.markers) return null;

        const apiKey = configuration.GOOGLE_MAP_API_KEY;
        const { h1, h2 } = this.props.data;
        const defaultCenter = this.getDefaultCenter();
        const defaultZoom = (this.props.data.map.defaultZoom) ? Number(this.props.data.map.defaultZoom) : this.props.zoom;
        
        return (
            <>
                {LayoutBuilder.buildH1(h1)}
                <div style={{ height: '80vh', width: '100%' }}>
                    <MarkerGoogleMap
                        defaultCenter={defaultCenter}
                        defaultZoom={defaultZoom}
                        options={{ styles: MapStyle }}
                        bootstrapURLKeys={{ key: apiKey }}>
                        {this.renderMarkers()}
                    </MarkerGoogleMap>
                </div>
                {LayoutBuilder.buildH2(h2)}
            </>
        )
    }

}

const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${configuration.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

const MapWithAKmlLayer = compose(
    withProps({
      googleMapURL: mapUrl,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `600px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(props =>
    {
        return (
            <LazyLoadComponent>
                <GoogleMap
                    defaultZoom={8}
                    defaultCenter={{ lat: 52.270542, lng: 19.089287 }}
                    defaultOptions={{ styles: MapStyle }}
                >
                <KmlLayer
                    url={`${configuration.ASSETS_URL}${props.data.map.kml}`}
                    options={{ preserveViewport: true }}
                />
                </GoogleMap>
            </LazyLoadComponent>
        );
    }
  );

const KMKGoogleMap = (props) => {
    const { kml } = props.data.map;
    const { html } = props.data['map-label'];
    return (
        <div className='af-map'>
            {html && <div className='af-map-label' dangerouslySetInnerHTML={{__html: html }}></div>}
            {kml ? <MapWithAKmlLayer {...props} /> : <PureKMKMapTile {...props} />}
        </div>
    );
}

export const KMKMapTile = UIComponent(KMKGoogleMap, 'kmk-map-tile', ['map']);
