import React from 'react';
import axios from 'axios';
import { configuration } from '../../configuration/configuration';
import CloseButton from '../../assets/icons/clear-button.svg';
import './policy-popup.css';

export class PolicyPopup extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { fields: {} };
        this.fetchPolicyBlock();
    }

    fetchPolicyBlock() {
        axios.get(configuration.BLOCKS_URL + '?name=PolicyPopup')
        .then(
            res => { this.setState({ fields: res.data.fields }); }
        );
    }

    render() {
        return (
            <>
                <div onClick={() => this.props.onClosed()} className='overlay'></div>
                <div className='policy-popup-container'>
                    <img onClick={() => this.props.onClosed()} className='close-popup' src={CloseButton} alt="close-button"/>
                    <h2>{this.state.fields.header}</h2>
                    <p>{this.state.fields.content}</p>
                </div>
            </>
        );
    }
}