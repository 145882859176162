import React from 'react';
import './loader.css';

export default function Loader() {
    return (
        <div className='loader-container'>
            <svg    
                className='loader'
                version="1.1" 
                id="Warstwa_1" 
                xmlns="http://www.w3.org/2000/svg" 
                x="0px" 
                y="0px"
                viewBox="0 0 34.7 34.7"
            >
                <g>
                    <g id="Warstwa_1-2">
                        <path d="M17.4,34.7c-2.4,0-4.6-1.4-5.7-3.6c-3.3,1.2-6.9-0.6-8.1-3.9c-0.5-1.4-0.5-2.8,0-4.2c-3.1-1.5-4.5-5.3-3-8.4
                            c0.6-1.3,1.7-2.3,3-3C2.4,8.4,4.2,4.8,7.5,3.6c1.4-0.5,2.8-0.5,4.2,0c1.5-3.1,5.3-4.5,8.4-3c1.3,0.6,2.3,1.7,3,3
                            c3.3-1.2,6.9,0.6,8.1,3.9c0.5,1.4,0.5,2.8,0,4.2c3.1,1.5,4.5,5.3,3,8.4c-0.6,1.3-1.7,2.3-3,3c1.2,3.3-0.6,6.9-3.9,8.1
                            c-1.4,0.5-2.8,0.5-4.2,0C22,33.3,19.8,34.7,17.4,34.7z M12.8,28.3l0.3,1.2c0.6,2.3,3,3.7,5.3,3.1c1.5-0.4,2.6-1.6,3.1-3.1l0.3-1.2
                            l1.1,0.6c2.1,1.2,4.7,0.5,5.9-1.6c0.8-1.3,0.8-3,0-4.3l-0.6-1.1l1.2-0.3c2.3-0.6,3.7-3,3.1-5.3c-0.4-1.5-1.6-2.6-3.1-3.1l-1.2-0.3
                            l0.6-1.1c1.2-2.1,0.5-4.7-1.6-5.9C26,5,24.3,5,23,5.8l-1.1,0.6l-0.3-1.2c-0.6-2.3-3-3.7-5.3-3.1c-1.5,0.4-2.6,1.6-3.1,3.1
                            l-0.3,1.2l-1.1-0.6C9.7,4.6,7,5.3,5.8,7.4c-0.8,1.3-0.8,3,0,4.4l0.6,1.1l-1.2,0.3c-2.3,0.6-3.7,3-3.1,5.3c0.4,1.5,1.6,2.6,3.1,3.1
                            l1.2,0.3L5.8,23c-1.2,2.1-0.5,4.7,1.5,5.9c1.3,0.8,3,0.8,4.4,0L12.8,28.3z"/>
                        <circle cx="17.4" cy="17.4" r="2.8"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}
