import React from 'react';
import UIComponent from "../../hoc/ui-component";
import LayoutBuilder from "../../layout-builder/layout-builder";
import SliderIndicator from "./slider-indicator";
import Swipe from 'react-easy-swipe';

class PureAFImageCarousel extends React.PureComponent {

    static defaultProps = {
        interval: 4000,
        height: '500px',
    };

    constructor(props) {
        super(props);
        this.imageSectionRef = React.createRef();
        this.setIndex = this.setIndex.bind(this);
        this.getSectionWidth = this.getSectionWidth.bind(this);
        this.state = {
            currentIndex: 0,
            data: { locations: [] },
            length: 0,
            index: 0,
            images: [],
            carouselImages: [],
            sectionWidth: 0,
        }
    }

    componentDidMount() {
        try {
            const data = JSON.parse(this.props.data.custom.data);
            const images = data.locations.map(l => ({
                srcLarge: l.image_l,
                srcSmall: l.image_s,
                maxHeight: l.maxHeight
            }));
            const maxIndex = data.locations[data.locations.length - 1].hasOwnProperty('image_l') ?  data.locations.length : (data.locations.length - 1);

            this.setState({ data, length: data.locations.length - 1, maxIndex, images }, () => {
                this.setImageSection();
                this.startCarousel();
            });
        } catch (e) {
            console.warn('Cannot parse component info: ', e.message);
        }
    }

    getSectionWidth() {
        return this.imageSectionRef.current.offsetWidth ;
    }

    incrementIndex() {
        const nextIndex = (this.state.index + 1) % (this.state.maxIndex);
        this.setState({ index: nextIndex }, () => {
            clearInterval(this.interval);
            this.setCarouselTranslation();
        });
    }

    decrementIndex() {
        const prevIndex = (this.state.index - 1 + this.state.maxIndex) % this.state.maxIndex;
        console.log()
        this.setState({ index: prevIndex }, () => {
            clearInterval(this.interval);
            this.setCarouselTranslation();
        });
    }

    setIndex(index) {
        if (index > this.state.length || index > this.state.maxIndex - 1) return;
        this.setState({ index }, () => {
            clearInterval(this.interval);
            this.setCarouselTranslation();
            this.startCarousel();
        });
    }

    setIndexWithTimeout(index) {
        const timeout = setTimeout(() => this.setIndex(index), 150);
        this.setState({ timeout });
    }

    clearIndexTimeout() {
        clearInterval(this.state.timeout);
    }

    startCarousel() {
        this.interval = setInterval(() => {
            this.incrementIndex();
            this.setCarouselTranslation();
        }, this.props.interval);
    }

    setCarouselTranslation() {
        const imageStrip = this.imageSectionRef.current.firstChild;
        imageStrip.childNodes.forEach((e, i) => {
            i===this.state.index ? e.style.opacity = '1' : e.style.opacity = '0';
        });
    }

    setImageSection() {
        const { images } = this.state;
        const sectionWidth = this.getSectionWidth();
        this.setState({ sectionWidth });
        const carouselImages = images.map((image, index) => {
            const src = window.innerWidth < 768 ? image.srcSmall : image.srcLarge;
            const html = this.state.data.locations[index].html;
            const style = { opacity: index===0 ? '1' : '0' };
            return src && (
                <div
                    key={index}
                    style={style}
                    className='image-container-element'
                >
                    <div
                        className='image-html-container'
                        dangerouslySetInnerHTML={{ __html: html }}
                    ></div>
                    <img
                        src={src}
                        style={{ width: `${sectionWidth}px`, minWidth: `${sectionWidth}px`, maxHeight: image.maxHeight, objectPosition: 'top left' }}
                        alt={`carousel-tile-${index}`}
                    />
                </div>
            );
        });

        this.setState({ carouselImages });
    }

    renderImageSection() {
        return this.state.carouselImages;
    }

    render() {
        const textColor = this.props.data.paragraph.color || '#000';
        const underlineStyle = { "--underline-color": textColor };
        return (
            <Swipe
                className='af-image-carousel-swiper'
                onSwipeLeft={() => this.incrementIndex()}
                onSwipeRight={() => this.decrementIndex()}
                onSwipeMove={(pos) => { if (Math.abs(pos.y) < 5) { return true; } }}
            >
                <div className='carousel-container'>
                    {LayoutBuilder.buildParagraph(this.props.data.paragraph)}
                    <ul>
                        {this.state.data.locations.map((l, i) => (
                            <li
                                key={i}
                                data-active={this.state.index===i}
                                style={{ color: textColor }}
                                onClick={() => this.setIndex(i)}
                                onMouseEnter={() => this.setIndexWithTimeout(i)}
                                onMouseLeave={() => this.clearIndexTimeout()}
                            ><h2 style={underlineStyle}><span>{l.name}</span></h2></li>)
                        )}
                    </ul>
                </div>
                <div
                    className='image-container'
                    ref={this.imageSectionRef}
                >
                    <div className='images-strip'>{this.renderImageSection()}</div>
                </div>
                <SliderIndicator
                    count={this.state.length}
                    index={this.state.index}
                />
            </Swipe>
        );
    }

}

export const AFImageCarousel = UIComponent(PureAFImageCarousel, 'af-image-carousel', ['custom']);
