import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { configuration } from '../configuration/configuration';
import UIComponent from '../hoc/ui-component';
import Avatar from '../assets/icons/user.svg';
import './ui-components.css';

class PureKMKBlogTile extends PureComponent {

    getAvatar() {
        const { author } = this.props.blog;
        const hasAvatar = author && author.avatars && author.avatars['48x48'];
        const source = configuration.STATICS_URL + author.avatars['48x48'];
        return hasAvatar ? (<img className="avatar" src={source} alt="Avatar"/>) : (<img className="avatar" src={Avatar} alt="Avatar"/>);
    }

    render() {
        if (!this.props.blog) return null;
        const { author, tagList, title, formattedPublishedAt } = this.props.blog;
        
        return (
            <>
                <h1>{title}</h1>
                <div className='kmk-blog-summary'>
                    {author && this.getAvatar() }
                    {author && <span>{author.name} {author.surname}</span>}
                    {tagList && tagList.map((tag, i) => (
                        <a  
                            key={i}
                            href={`/szukaj?tag[]=${tag}`}
                            className='kmk-blog-tag'
                        >#{tag} </a>
                    ))}
                    <span style={{marginLeft: '15px'}}>{formattedPublishedAt}</span>
                </div>
            </>
        );
    }
}

export default connect((state) => state, null)(UIComponent(PureKMKBlogTile, 'kmk-blog-tile', []));
