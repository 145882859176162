import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './home/home';
import KMKPage from './page/page';
import BlogPage from './blog/blog-page';
import { NotFound } from './page/page_not_found';
import { SearchPage } from './search/search-page';

const Main = () => (
    <main>
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path="/blog" component={BlogPage}/>
            <Route exact path='/szukaj' component={SearchPage}/>
            <Route exact path="/not-found" component={NotFound}/>
            <Route path='/*' component={KMKPage}/>
        </Switch>
        
    </main>
)

export default Main;