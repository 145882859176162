import React from 'react';
import useCMSBlock from "../../hoc/use-cms-block";
import EventBus from "../../event-bus/event-bus";

class PureTopBarInfo extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onContactButtonClicked = this.onContactButtonClicked.bind(this);
    }

    onContactButtonClicked() {
        if (!this.props.data.contact_us_event || !this.props.data.contact_us_event.hasOwnProperty('name')) { return; }
        const event = this.props.data.contact_us_event;
        EventBus.dispatchEvent(event.name, event.data);
    }

    isActive(link) {
        return !!!window.location.pathname.indexOf(link)
    }

    render() {
        const negativeContrast = this.props.contrast === '#FFF' ? '#000' : 'transparent';
        const buttonStyle = {
            backgroundColor: negativeContrast,
            border: '1px solid #000',
            color: `${this.props.contrast}`
        };
        return (
            <div style={{ color: this.props.contrast }} className='top-bar-info-container'>
                <span>
                    <a href={`tel:${this.props.data.contact_number}`}>{this.props.data.contact_number}</a>
                </span>
                <i style={{ backgroundColor: this.props.contrast }}></i>
                <span style={{ marginLeft: '2rem' }}><a href={`mailto:${this.props.data.contact_email}`}>{this.props.data.contact_email}</a></span>
                <span>
                    <a
                        href={this.props.data.contact_href}
                        data-active={this.isActive(this.props.data.contact_href)}
                    >{this.props.data.contact_label}</a>
                </span>
                <span className='last-el'>
                    <a
                        href={this.props.data.about_us_href}
                        data-active={this.isActive(this.props.data.about_us_href)}
                    >{this.props.data.about_us_label}</a>
                </span>
                <button
                    data-no-change
                    style={buttonStyle}
                    onClick={this.onContactButtonClicked}
                >{this.props.data.contact_us_button_label}</button>
            </div>
        );
    }
}

const TopBarInfo = useCMSBlock(PureTopBarInfo, 'top-bar-info');
export default TopBarInfo;
