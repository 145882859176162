import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureKMKContactTile extends React.PureComponent {

    render() {
        const { h1, h2, paragraph, images } = this.props.data;
        return (
            <>
                {LayoutBuilder.buildH1(h1)}
                {LayoutBuilder.getStyledImages(images)}
                {LayoutBuilder.buildH2(h2)}
                {LayoutBuilder.buildParagraph(paragraph)}
            </>
        );
    }
}

export const KMKContactTile = UIComponent(PureKMKContactTile, 'kmk-contact-tile', ['h1', 'h2', 'paragraph']);
