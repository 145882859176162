import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import SVGMarker from '../../assets/icons/map-marker.svg';
import './another-floor-blocks.css';

class PureAFWorldWideBlock extends React.Component {

    constructor(props) {
        super(props);
        this.parseCustomData(this.props.data.custom.data);
    }

    componentDidMount() {
        this.rotateActiveLocation();
    }

    parseCustomData(data) {
        try {
            const parsedCustom = JSON.parse(data);
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state = {
                data: parsedCustom,
                currentLocation: parsedCustom.locations[0],
                containerWidth: 700
            };
            // eslint-disable-next-line react/no-direct-mutation-state
        } catch (e) { this.state = {}; }
    }

    setActiveLocation(location) {
        clearInterval(this.state.interval);
        this.rotateActiveLocation();
        this.setState({ currentLocation: location });
    }

    rotateActiveLocation() {
        const interval = setInterval(() => {
            const currentLocationIndex = this.state.data.locations.findIndex(l => l.name === this.state.currentLocation.name);
            const nextIndex = (currentLocationIndex + 1) % this.state.data.locations.length;
            this.setActiveLocation(this.state.data.locations[nextIndex]);
        }, 5000);
        this.setState({ interval });
    }

    isActive(location) {
        return location.name === this.state.currentLocation.name;
    }

    getMapContainerScale() {
        if (window.innerWidth > 768) { return null; }
        const factor = .8;
        const value = (window.innerWidth / this.state.containerWidth) * factor;
        const scale = { transform: `scale(${value})` };

        return { ...scale };
    }

    render() {
        if (!this.state.data) { return null; }
        return (
            <>
                <div className='list-container'>
                    <label>{this.state.data.header}</label>
                    <ul>
                        {this.state.data.locations.map((location, key) => (
                            <li
                                key={key}
                                className='location'
                                data-active={this.isActive(location)}
                                onClick={() => this.setActiveLocation(location)}
                            ><span>{location.name}</span></li>
                        ))}
                    </ul>
                </div>
                <div className='map-container' style={this.getMapContainerScale()}>
                    <img src={this.state.data.map} alt='Map' />
                    {this.state.data.locations.map((location, i) => {
                        const markerStyle = { ...location.position };
                        const markerInfoClass = `map-marker-info origin-${location.position.origin}`;
                        return (
                            <div
                                key={i}
                                style={markerStyle}
                                data-active={this.isActive(location)}
                                className='map-marker-container'
                                onClick={() => this.setActiveLocation(location)}
                            >
                                <img
                                    className='map-marker'
                                    src={SVGMarker}
                                    alt='Marker'
                                />
                                <div className={markerInfoClass} dangerouslySetInnerHTML={{ __html: location.html}}></div>
                            </div>
                        )
                    })}
                </div>
            </>
        );
    }
}

export const AFWorldWide = UIComponent(PureAFWorldWideBlock, COMPONENT_MAP.AFWORLDWIDE, []);
