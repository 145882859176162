import React from 'react';

function TextAreaField(props) {
    const { name, label, error } = props;
    if (!name) return null;
    return (
        <div className='form-input' data-error={!!error} key={name}>
            <label 
                htmlFor={name}
                aria-label={name}
            ></label>
            <textarea
                id={name}
                onFocus={() => props.onFocus(name)}
                key={name}
                className='kmk-form-textarea'
                name={name}
                placeholder={label}
                onChange={(value) => props.onChange(value)}
            />
            { error && error.map(e => (<span key={e} className='form-input-error'>{e}</span>))}
        </div>
    )
}

export default TextAreaField;
