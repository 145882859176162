import React from 'react';
import Hamburger from '../hamburger/hamburger';
import { KMKMenuModal } from './kmk-menu-modal';

export class KMKMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { component: 'KMKMenuComponent', opened: false };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({ opened: !this.state.opened });
        if (!this.state.opened) { document.body.classList.add('lock-scroll'); } 
        else { document.body.classList.remove('lock-scroll'); }
    }

    render() {
        return (
            <>
                <Hamburger
                    {...this.props}
                    inverted={this.props.contrast}
                    onClick={ () => this.toggleMenu() }
                    active={ this.state.opened }
                />
                <KMKMenuModal
                    fields={ this.state.fields }
                    active={ this.state.opened }
                    onClose={() => this.toggleMenu()}
                />
            </>
        );
    }
}
