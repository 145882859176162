import React from 'react';
import axios from 'axios';
import { configuration } from '../../configuration/configuration';
import './kmk-menu.css';
import {SVGLogo} from "../logo/svg-logo";
import {withRouter} from "react-router-dom";

class StatelessKMKMenuModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {}
        };
    }

    componentDidMount() {
        this.fetchComponentData('af-menu');
    }

    fetchComponentData(blockName) {
        if (Object.keys(this.state.fields).length === 0) {
            axios.get(configuration.BLOCKS_URL + '?name=' + blockName)
                .then(res => this.setState({fields: res.data.fields}))
                .catch(() => this.setState({ fields: {} }));
        }
    }

    isActive(href) {
        return href === window.location.pathname;
    }

    navigateToHome() {
        this.props.history.push('/');
        this.props.onClose();
    }

    render() {
        let component_class = 'kmk-menu';
        let overlay_component_class = 'kmk-menu-overlay';
        if (this.props.active) { 
            overlay_component_class += ' kmk-menu-overlay-active';
            component_class += ' kmk-menu-active';
        }
        return (
            <>
                {this.props.active && <div className={ overlay_component_class }></div>}
                <div className={component_class}>
                    <SVGLogo onClick={() => this.navigateToHome()} className='menu-logo' contrast='#FFF'/>
                    <div className='menu-inner-container'>
                        <div className='links-container'>
                            {this.state.fields.links && this.state.fields.links.map((link, i) => (<a key={i} href={link.href} data-active={this.isActive(link.href)}>{link.label}</a>))}
                        </div>
                        {this.state.fields.contact && <div className='contact-container'>
                            <label>{this.state.fields.contact.get_in_touch}</label>
                            <div dangerouslySetInnerHTML={{ __html: this.state.fields.contact.contact_html}} ></div>
                            <label>{this.state.fields.contact.follow_us}</label>
                            {this.state.fields.contact.icons.map((i, index) => {
                                const btnStyle = { backgroundImage: `url(${i.icon})` };
                                return (
                                    <a key={index} target='_blank' rel="noopener noreferrer" href={i.href}><button key={index} className='menu-icon' style={btnStyle}></button></a>
                                );
                            })}
                        </div>}
                    </div>
                </div>
            </>
        );
    }
}

export const KMKMenuModal = withRouter(StatelessKMKMenuModal);
