export const COMPONENT_MAP = {
    KMKTITLETILE: 'kmk-title-tile',
    KMKFOOTERTILE: 'kmk-footer-tile',
    KMKTEXTTILE: 'kmk-text-tile',
    KMKCONTACTTILE: 'kmk-contact-tile',
    KMKIMAGETILE: 'af-image',
    KMKLISTTILE: 'kmk-list-tile',
    KMKMEDIALINKTILE: 'kmk-media-link-tile',
    KMKPEOPLETILE: 'kmk-people-tile',
    KMKIMAGETEXTTILE: 'kmk-image-text-tile',
    KMKLINKEMBEDEDTILE: 'kmk-link-embeded-tile',
    KMKCAROUSELTILE: 'kmk-carousel-tile',
    KMKVIDEOTILE: 'kmk-video-tile',
    KMKCTATILE: 'kmk-cta-tile',
    KMKMAPTILE: 'kmk-map-tile',
    KMKCARDTILE: 'kmk-card-tile',
    KMKBLOGTILE: 'kmk-blog-tile',
    KMKSUMMARYTILE: 'kmk-summary-tile',
    KMKFORMTILE: 'kmk-form-tile',
    KMKCUSTOMTILE: 'kmk-custom-tile',
    KMKBUTTONSTILE: 'kmk-buttons-tile',
    KMKTABLEOFCONTENTS: 'kmk-table-of-contents-tile',
    KMKINSTAGRAMFEED: 'kmk-instagram-feed',
    KMKAPPOINTLYTILE: 'kmk-appointly-tile',
    KMKFOOTERLP: 'kmk-footer-lp',
    AFHEADER: 'af-header',
    AFHEADERHOVER: 'af-header-hover',
    AFWORLDWIDE: 'af-worldwide',
    AFCARD: 'af-card',
    AFFOOTER: 'af-footer',
    AFCTA: 'af-cta',
    AFHTML: 'af-html',
    AFTEXT: 'af-text',
    AFBUTTON: 'af-button',
    AFCTABACKGROUND: 'af-cta-background',
    AFTIMER: 'af-timer',
    AFPERSONS: 'af-persons',
    AFTEXTCAROUSEL: 'af-text-carousel',
    AFMAP: 'af-map',
    AFVIDEOBANNER: 'af-video-banner',
    AFRESPONSIVEBANNER: 'af-responsive-banner',
    AFIMAGECAROUSEL: 'af-image-carousel',
    AFCOLUMNHTML: 'af-column-html',
    AFIMAGETRANSITION: 'af-image-transition',
    AFBOOKLET: 'af-booklet',
    AFVIDEO: 'af-video',
};
