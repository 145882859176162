import React, { PureComponent } from 'react';
import './blog-tile.css';
import { configuration } from '../configuration/configuration';

class BlogTile extends PureComponent {
    
    renderBlogTile() {
        const { thumbnail, article, author, distinctive } = this.props;
        const { title, endpoint, blog } = article;
        const { tagList, excerpt, formattedPublishedAt } = blog;
        const thumbnailStyle = {
            backgroundImage: `url(${configuration.STATICS_URL}${thumbnail})`,
            height: distinctive ? '500px' : '250px',
        };

        return (
            <a href={`/${endpoint}`} className={`blog-tile ${distinctive ? 'distinctive-tile' : ''}`}>
                <div 
                    style={thumbnailStyle} 
                    className='thumbnail'
                ></div>
                { distinctive ? <h1>{title}</h1> : <h2>{title}</h2> }
                {excerpt && <p>{excerpt}</p>}
                <div className='blog-tile-details'>
                    <>
                        {author && <span className='author'>{author.name} {author.surname}</span>}
                        {tagList && tagList.map((t, i) => (
                            <a 
                                key={i}
                                className='tags'
                                href={`/szukaj?tag[]=${t}`}
                            > #{t.toUpperCase()}</a>
                        ))}
                        {formattedPublishedAt&& <span className='date'>{formattedPublishedAt}</span>}
                    </>
                </div>
            </a>
        );
    }

    render() {
        return this.renderBlogTile();
    }
}

export default BlogTile;