import React from 'react';
import UIComponent from '../hoc/ui-component';
import { configuration } from '../configuration/configuration';
import axios from 'axios';
import instagram_logo from '../../src/assets/icons/instagram_logo.svg';
import likes_icon from '../../src/assets/icons/likes_icon.svg';
import comment_icon from '../../src/assets/icons/comment_icon.svg';
import './ui-components.css';

function getCreatedTimeLabel(time) {
    const diff = (Math.floor(Date.now() / 1000)) - time;
    const HOUR = 60 * 60;
    const DAY = HOUR * 24;
    if (diff < HOUR) { return 'Godzinę temu'; }
    else if (diff > HOUR && diff < DAY) {
        return `${Math.floor(diff / 60 / 60)} godzin temu`;
    } else{
        const date = new Date(time * 1000);
        const day = ('0' + date.getDate()).substr(-2);
        const month = ('0' + date.getMonth()).substr(-2);
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }
}

function InstagramFeedTile(props) {
    const { profile_picture, full_name, username } = props.feed.user;
    const { standard_resolution } = props.feed.images;
    const feed_image = {
        backgroundImage: `url(${standard_resolution.url})`
    }
    
    return props && (
        <a className='instagram-feed-tile' href={props.feed.link} target='_blank' rel="noopener noreferrer">
            <div className='instagram-feed-tile-header'>
                { profile_picture && <img className='user-avatar' src={profile_picture} alt={full_name} /> }    
                <div className='feed-details'>
                    <label className="full-name">{username}</label>
                    <label className="post-date">{getCreatedTimeLabel(props.feed.created_time)}</label>
                </div>
                <div className='empty-grow'></div>
                <img 
                    className='instagram-logo'
                    src={instagram_logo} 
                    alt="instagram-logo"
                />
            </div>

            <div
                style={feed_image} 
                className='feed-image'
            ></div>

            <div className='feed-manage-bar'>
                <div className='feed-manage-section'>
                    <img src={likes_icon} alt="Likes" />
                    <span>{props.feed.likes.count || 0}</span>
                </div>
                <div className='feed-manage-section'>
                    <img src={comment_icon} alt="Comments" />
                    <span>{props.feed.comments.count || 0}</span>
                </div>
            </div>

            <div className='feed-content'>
                <span className='caption'>{props.feed.caption.text}{props.feed.tags.map(tag => (`#${tag}`))}</span>
            </div>
        </a>
    )
}

class PureKmkInstagramFeed extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            feed: []
        };
    }

    fetchInstafeed() {
        axios.get(configuration.INSTAGRAM_URL + configuration.INSTAGRAM_ACCESS_TOKEN + `&count=${Number(this.props.data.instagram.content)}`)
            .then(res => {
                const { data } = res.data;
                this.setState({ feed: data })
            });
    }

    componentDidMount() {
        this.fetchInstafeed();
    }

    render() {
        return (
            <div className="kmk-instafeed">
                {this.state.feed.map(feed => <InstagramFeedTile feed={feed} key={feed.id} />)}
            </div>
        );
    }
}

export const KMKInstagramFeed = UIComponent(PureKmkInstagramFeed, 'kmk-instagram-feed', []);
