import React from 'react';
import CTACarousel from "./cta-carousel/cta-carousel";
import LogoCarousel from './logo-carousel/logo-carousel';

const CustomComponentFactory = (name, props) => {
    const CUSTOM_COMPONENTS = {
        'cta-carousel': <CTACarousel {...props} />,
        'logo-carousel': <LogoCarousel {...props} />
    };

    return CUSTOM_COMPONENTS[name];
};

export default CustomComponentFactory;