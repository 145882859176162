import React from 'react';
import TopBar from '../ui-components/top-bar/top-bar';
import CookiesBanner from '../ui-components/custom-components/cookies-banner/cookies-banner';
import axios from 'axios';
import { configuration } from '../configuration/configuration';
import { LatestArticleTile } from './latest-articles';
import { SearchTile } from './search-tile';
import { animateScroll as scroll } from 'react-scroll';
import buildQuery from './search-query';
import './search-page.css';
import Loader from '../loader/loader';
import { KMKFooterTile } from '../ui-components/kmk-footer-tile';

export class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        this.fetchSearchPage();
        this.fetchBlogArticles();
        this.initialize(true, this.props.location.search);
    }

    initialize(fromConstructor, search) {
        if (!search) { 
            if (fromConstructor)  { this.setState({ error: true, latestArticles: [], page: {}, results: [], query: '' }); }
            else { this.setState({ error: true, latestArticles: [], page: {}, results: [], query: '' }); }

            return; 
        }

        let query = search.match(/q=[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ%0-9]+/);
        let tags = decodeURIComponent(search).match(/tag\[\]=\w+/);

        tags = this.decodeTagQuery(tags);
        query = this.decodeQuery(query);
        
        if (fromConstructor) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state = { 
                query: query || this.stringifyTags(tags), 
                page: {}, 
                latestArticles: [],
                results: [],
                pending: true,
            };
        } else {
            this.setState({
                query,
                error: false,
                results: [],
                pending: true,
            })
        }

        this.fetchSearchResults(query);
        this.fetchTagsResults(tags);
    }

    componentWillMount() {
        this.props.history.listen((location, action) => {
            this.initialize(false, location.search);
        });
    }

    decodeTagQuery(match) {
        if (!match || match.length === 0) { return []; }
        return match.reduce((acc, match) => {
            const encodedTag = decodeURIComponent(match.split('=')[1]);
            return [...acc, encodedTag];
        }, []);
    }

    decodeQuery(match) {
        if (!match || match.length === 0) { return null; }
        const query = match[0].split('=')[1];

        return decodeURIComponent(query);
    }

    stringifyTags(tags) {
        return tags.map(t => `#${t}`).join(' ');
    }

    fetchSearchResults(query) {
        if (!query) { return; }
        axios.get(configuration.API_URL + '/public/search?query=' + query)
            .then(res => {
               const { data } = res;
               this.setState({ type: 'query', results: data.search, pending: false });
               scroll.scrollTo(0);
            })
            .catch(() => {
                this.setState({ type: 'query', error: true, pending: false });
                scroll.scrollTo(0);
            })
    }

    fetchTagsResults(tags) {
        if (tags.length === 0) { return; }
        axios.get(configuration.API_URL + '/public/search?' + buildQuery(tags))
            .then(res => {
                const { data } = res;
                if (data.search.length > 0) {
                    this.setState({ type: 'tag', results: data.search, pending: false });
                } else {
                    throw new Error('Not found');
                }
            })
            .catch(() => {
                this.setState({ type: 'tag', error: true, pending: false })
            })
    }

    fetchBlogArticles() {
        axios.get(configuration.API_URL + '/blog/articles?limit=3')
            .then(res => {
                const { data } = res;
                this.setState({ latestArticles: data });
            })
            .catch(e => {
            })
    }

    fetchSearchPage() {
        axios.get(configuration.BLOCKS_URL + '?name=KMKSearchPage')
            .then(res => {
                const { data } = res;
                this.setState({ page: data.fields });
            });
    }

    render() {
        return (
            <>
                <TopBar />
                <CookiesBanner />
                {this.state.pending && <Loader />}
                <div className='search-page-container'>
                    {
                        this.state.error && 
                        <h1>
                            {
                                this.state.type === 'query' ? 
                                this.state.page.no_results : 
                                this.state.page.no_tags_results
                            }
                            {` "${this.state.query}"`}
                        </h1>
                    }
                    {
                        this.state.results.length > 0 &&
                        <h1>{this.state.page.search_results_h1}{` "${this.state.query}"`}</h1>
                    }
                    <div className='search-results-container'>
                        {this.state.results.map((res, index) => 
                            (<SearchTile
                                key={index}
                                index={index}
                                title={res.title}
                                src={res.thumbnail}
                                endpoint={res.endpoint}
                                occurance={res.occurances ? res.occurances[0] : null}
                            />) 
                        )}
                    </div>
                    <div className='search-page-latest-articles-container'>
                        <h2 className='latest-articles-label'>{this.state.page.latest_articles}</h2>
                        <div className='latest-articles-container'>
                            {this.state.latestArticles.map((a, index) => (
                                <LatestArticleTile
                                    key={index}
                                    index={index}
                                    src={a.thumbnail} 
                                    alt={a.article.title}
                                    title={a.article.title}
                                    excerpt={a.article.excerpt}
                                    endpoint={a.article.endpoint}
                                    author={a.author}
                                    tagList={a.article.blog.tagList}
                                    published={a.article.blog.formattedPublishedAt}
                                />
                            ) )}
                        </div>
                    </div>
                </div>
                <KMKFooterTile />
            </>
        );
    }
}
