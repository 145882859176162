import React from 'react';
import { connect } from 'react-redux';
import UIComponent from '../hoc/ui-component';
import LayoutBuilder from '../layout-builder/layout-builder';
import { getSrcForRwd } from '../rwd/rwd';

class PureKMKImageTile extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { 
            isVisible: this.props.devMode,
        };
        this.blockRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
        this.preventDefault = this.preventDefault.bind(this);
    }

    initializeProdMode() {
        const images = this.getStylesImages();
        this.setState({ images });
    }

    handleScroll() {
        if (!this.blockRef.current) return null;
        const rect = this.blockRef.current.getBoundingClientRect();
        const isVisible = (rect.top <= window.innerHeight - 200 || rect.bottom <= window.innerHeight + 180);
        if (isVisible && !this.state.isVisible && !this.props.devMode) {
            this.state.imagesRefs.forEach( img => img.current.setAttribute('data-visible', true) );
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;  
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if (!this.props.devMode) this.initializeProdMode();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    getStylesImages() {
        const { images, image_fit } = this.props.data;
        const output_images = [];
        let images_refs = [];
        for (let i = 0; i < images.length; i++) {
            const imageStyle = { 
                width: images[i].width ? images[i].width : '100%', 
                height: images[i].height, 
                padding: images[i].padding,
                objectFit: image_fit || 'none',
            };

            images_refs = [...images_refs, React.createRef()];

            const image = (
                <img 
                    key={ i } 
                    ref={ images_refs[i] } 
                    data-dontscale={ images[i].dontScale ? true : null } 
                    data-nomargin={ images[i].noMargin ? true : null } 
                    style={ imageStyle } 
                    src={ getSrcForRwd(images[i].source) } 
                    alt={ images[i].alt }
                />);

            output_images.push(image);
        }

        this.setState({ imagesRefs: images_refs }, () => this.handleScroll());

        return output_images;
    }

    renderProdMode() {
        const images = this.props.data.images.images || this.props.data.images;
        const style = { padding: this.props.data.options.padding };
        return (
            <div style={style} className={`stack-images-${images.length}`}>
                {LayoutBuilder.getStyledImages(images)}
                {LayoutBuilder.getStyledLogo(this.props.data)}
            </div>
        )
    }

    render() {
        return this.renderProdMode();
    }
}

const mapStateToProps = state => state;

const KMKImageTile = UIComponent(PureKMKImageTile, 'kmk-image-tile', ['images']);

export default connect(mapStateToProps)(KMKImageTile);
