/* eslint-disable no-useless-escape */
import React from 'react';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureTableOfContents extends React.PureComponent {

    offsetCalulationTimeout = null;

    constructor(props) {
        super(props);
        this.state = {
            hasColumnLayout: false,
            sanitizedContent: null,
        };
        this.setTableOfContent = this.setTableOfContent.bind(this);
    }

    fetchFirstLink() {
        this.offsetCalulationTimeout = setTimeout(() => {
            if (this.state.offset) { clearTimeout(this.offsetCalulationTimeout); return; }
            const tableOfContentsContainer = document.querySelector('.kmk-table-of-contents-tile');
            if (!tableOfContentsContainer) { return; }
            const links = tableOfContentsContainer.getElementsByTagName('a');
            if (!links || links.length === 0) return;
            const firstLink = links[0];
            const offset = firstLink.offsetTop;
            this.setState({ offset });
        }, 100);
    }

    setTableOfContent() {
        const { tableOfContent } = this.props.data.tableOfContent;
        if (!tableOfContent) { return; }
        
        let sanitizedContent = tableOfContent.replace(/target\=\"\_blank\"/gi, '');
    
        const columnRegex = /\<p\>\<br\>\<\/p\>\<p\>\<br\>\<\/p\>/gi;
        const hasColumnLayout = columnRegex.test(tableOfContent);

        if(hasColumnLayout) {
            const columnLayout = sanitizedContent.split(columnRegex)
                .filter(el => el.length > 0)
                .map( (col, i) => {
                    if (i === 0) return `<div>${col}</div>`;
                    else return `<div style="margin-top: calc(${this.state.offset}px - .1rem)">${col}</div>`
                });
            
            sanitizedContent = columnLayout.join('');
        }

        this.setState({ hasColumnLayout, sanitizedContent });
    }


    render() {
        this.setTableOfContent();
        this.fetchFirstLink();
        return (
            <div className={`kmk-table-of-contents ${this.state.hasColumnLayout ? 'column-layout' : ''}`} dangerouslySetInnerHTML={{__html: this.state.sanitizedContent}}></div>
        );
    }
}

export const KMKTableOfContents = UIComponent(PureTableOfContents, 'kmk-table-of-contents-tile', ['tableOfContent']);
