import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";

class PureHtmlColumnHtml extends React.Component {

    render() {
        const {left, right} = this.props.data;
        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: left.html }}></div>
                <div dangerouslySetInnerHTML={{ __html: right.html }}></div>
            </>
        );
    }
}

const AFColumnHtml = UIComponent(PureHtmlColumnHtml, COMPONENT_MAP.AFCOLUMNHTML, []);
export default AFColumnHtml;
