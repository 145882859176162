import React from 'react';
import LayoutBuilder from '../layout-builder/layout-builder';
import UIComponent from '../hoc/ui-component';
import './ui-components.css';

class PureKMKMediaLinkTile extends React.PureComponent {

    render() {
        const { link, paragraph, images } = this.props.data;
        return (
            <> 
                {LayoutBuilder.getStyledImages(images)}
                {LayoutBuilder.buildLink(link)}
                {LayoutBuilder.buildParagraph(paragraph)}
            </>
        );
    }
}

export const KMKMediaLinkTile = UIComponent(PureKMKMediaLinkTile, 'kmk-media-tile', ['link', 'paragraph']);
