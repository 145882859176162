/* eslint-disable no-useless-escape */
import React from 'react';
import UIComponent from '../hoc/ui-component';
import { configuration } from '../configuration/configuration';

class PureKMKVideoTile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { 
            embed: this.sanitize(this.props.data.video.embed),
        };
    }

    sanitize(content) {
        if (!content) return null;

        const wRegex = /width\=\"[0-9]+\"/;
        const hRegex = /height\=\"[0-9]+\"/;
        let output = content;
        if (wRegex.test(content)) { output = content.replace(wRegex, ''); }
        if (hRegex.test(content)) { output = content.replace(hRegex, ''); }
    
        return output;
    }

    renderEmbededVideo() {
        return (<div dangerouslySetInnerHTML={{ __html: this.state.embed }}></div>);
    }

    renderAssetVideo() {
        const { asset } = this.props.data.video;
        return (
            <div>
                <video
                    autoPlay
                    playsInline
                    muted
                    loop
                >
                    <source src={configuration.ASSETS_URL + asset} type="video/mp4" />
                </video>
            </div>
        )
    }

    render() {
        const renderAsBackground = this.props.data.video.asset && this.props.data.video.asset.length > 0;
        return renderAsBackground ? this.renderAssetVideo() : this.renderEmbededVideo();
    }
}

export const KMKVideoTile = UIComponent(PureKMKVideoTile, 'kmk-video-tile', ['video']);
