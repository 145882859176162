import React from 'react';
import UIComponent from "../../hoc/ui-component";
import {COMPONENT_MAP} from "../../builder/component-map";
import LayoutBuilder from "../../layout-builder/layout-builder";
import {LazyLoadComponent} from "react-lazy-load-image-component";

class PureAFCTABlock extends React.Component {
    getAlign(images) {
        if (!images || !images.align) { return 'right'; }
        return images.align;
    }

    render() {
        const wrapperClass = `af-cta-wrapper align-${this.getAlign(this.props.data.images)}`;
        return (
            <LazyLoadComponent delayTime={2000}>
                <div className={`${wrapperClass} animated-content`}>
                    <div className='text-container'>
                        {this.props.data.htmlContent && <div dangerouslySetInnerHTML={{ __html: this.props.data.htmlContent.html }}></div>}
                        {this.props.data.buttons && <div className='buttons-container'>{LayoutBuilder.buildButtons(this.props.data.buttons.buttons)}</div>}
                    </div>
                    <div className='image-container'>
                        {LayoutBuilder.getStyledLogo(this.props.data)}
                        {this.props.data.images && LayoutBuilder.getStyledImage(this.props.data.images.images)}
                    </div>
                </div>
            </LazyLoadComponent>
        );
    }
}

export const AFCTA = UIComponent(PureAFCTABlock, COMPONENT_MAP.AFCTA, []);
