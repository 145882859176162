import React, { PureComponent } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import LayoutBuilder from '../layout-builder/layout-builder';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

export class SearchTile extends PureComponent {

    render() {
        const style = { margin: '0 15px 0 15px' };
        return (
            <a 
                href={this.props.endpoint}
                className='latest-article-tile' 
                style={((this.props.index % 3 === 1 && this.props.index !== 0) || this.props.index ===1) ? style : {} } 
            >
                <div className='image-container'>
                {LayoutBuilder.getStyledImages([{ source: this.props.src, alt: '' }])}
                </div>
                <div className='text-container'>
                <h2>
                    <LinesEllipsis
                        text={this.props.title}
                        maxLine='3'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                    />
                </h2>
                {this.props.occurance && 
                    <HTMLEllipsis
                        unsafeHTML={this.props.occurance}
                        maxLine='5'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                    />
                }
                </div>
            </a>
        )
      }
}
