import React from 'react';
import axios from "axios";
import {configuration} from "../configuration/configuration";
import EventBus, {APPLICATION_EVENTS} from "../event-bus/event-bus";
import omit from "lodash-es/omit";
import FIELDS_MAP from "./form-fields";

export class ReactiveForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            fields: [],
            status: 0,
        };
        this.clearFieldErrors = this.clearFieldErrors.bind(this);
        this.buildForm = this.buildForm.bind(this);
        this.fetchFormSchema();
    }

    fetchFormSchema() {
        axios.get(configuration.API_URL + `/public/form/${this.props.formSchema}`)
            .then(response => {
                const { name, fields, _id } = response.data;
                this.setState({ name, fields, _id });
            })
            .catch(() => this.setState({ fields: [] }));
    }

    buildForm() {
        const { fields } = this.state;
        const fieldComponent = (type, field) => FIELDS_MAP[type] ? FIELDS_MAP[type](field) : null;

        if (!fields || fields.length === 0) return null;

        return fields.map((field, index) => {
            const fieldProps = {
                ...field,
                key: index,
                onChange: (e) => this.fieldChange(field.name, e),
                onFocus: (name) => this.clearFieldErrors(name),
                onEnterPressed: () => this.submit()
            };

            return fieldComponent(field.type, fieldProps);
        });
    }

    fieldChange(fieldName, event) {
        const { fields } = this.state;
        const fieldIndex = fields.findIndex(field => field.name === fieldName);
        fields[fieldIndex].value = event.target.value;
        this.setState({ fields }, () => this.clearFieldErrors(fieldName));
    }

    submit() {
        if (this.state.status === 1) return;

        const payload = {
            _id: this.state._id,
            lang: this.props.lang || 'pl',
            fields: this.state.fields.map(field => ({ name: field.name, value: field.value || null })),
        };

        axios.post(configuration.API_URL + '/cms/form', payload)
            .then((res) => {
                EventBus.dispatchEvent(APPLICATION_EVENTS.FORM_SUBMITED, null);
                this.setState({ status: 1, postMessage: res.data.postFunction.postMessage });
            })
            .catch(e => {
                const errors = e.response.data.errorMessage;
                this.handleFormValidation(errors);
            });
    }

    handleFormValidation(errors) {
        let { fields } = this.state;
        fields = fields.map(field => {
            const { name } = field;
            const possibleError = errors[name];
            if (possibleError) return { ...field, error: errors[name] };
            return field;
        });
        this.setState({ fields });
    }

    clearFieldErrors(fieldName) {
        let clearedFields = [...this.state.fields].map(field => {
            if (field.name === fieldName && field.error) { return omit(field, ['error']); }
            return field;
        });

        this.setState({ fields: clearedFields });
    }

    render() {
        if (!this.state.fields || this.state.fields.length === 0) { return null; }
        const submitted = this.state.status === 1;
        return (
            <>
                {this.buildForm()}
                {this.state.postMessage && <p className='form-post-message'>{this.state.postMessage}</p>}
                {!submitted && <div className='form-submit-button-container'><button
                    onClick={() => this.submit()}
                    className={`form-submit-button ${submitted ? 'submitted' : ''} `}
                >{this.props.sendButtonLabel}</button></div>}
            </>
        )
    }
}
